export const EMAIL_USECASES = {
  INVITE_REVIEWER: 'InvitationToReviewAManuscript(Reviewer)Wiley',

  ACCEPT: {
    WILEY: 'PRCCCompleted(SubmittingAuthor)Wiley',
    E2E: 'PRCCCompleted(SubmittingAuthor)E2E',
  },
  REJECT: {
    WITH_PEER_REVIEW: 'EditorialDecisionToRejectAfterPeerReview(AllAuthors)E2E',
    WITHOUT_PEER_REVIEW:
      'EditorialDecisionToRejectWithNoPeerReviews(AllAuthors)E2E',
  },
  REJECT_AND_REFER: 'RejectAndRefer(Authors)',
  REVISION_DECISION: {
    MAJOR: 'MajorEditorialRecommendationToRevise(Author)E2E',
    MINOR: 'MinorEditorialRecommendationToRevise(Author)E2E',
  },
}
