export const KEYCLOAK_ROLES = Object.freeze({
  ADMIN: 'admin',
  EA: 'editorial_assistant',
  AE: 'academic_editor',
  JM: 'journal_manager',
  TE: 'triage_editor',
})

export const ROLES = Object.freeze({
  USER: 'user',
  ADMIN: 'admin',
  AUTHOR: 'author',
  REVIEWER: 'reviewer',
  TE: 'triageEditor',
  AE: 'academicEditor',
  EA: 'editorialAssistant',
  SUBMITTING_STAFF_MEMBER: 'submittingStaffMember',
})

export const ROLES_LABELS = ({
  academicEditorLabel,
  triageEditorLabel,
}: {
  academicEditorLabel?: string
  triageEditorLabel?: string
}): Record<string, string> => {
  return Object.freeze({
    [ROLES.ADMIN]: 'Admin',
    [ROLES.AUTHOR]: 'Author',
    [ROLES.REVIEWER]: 'Reviewer',
    [ROLES.TE]: triageEditorLabel || 'Triage Editor',
    [ROLES.AE]: academicEditorLabel || 'Academic Editor',
    [ROLES.EA]: 'Editorial Assistant',
  })
}

export const getShortRole = ({
  academicEditorLabel,
  triageEditorLabel,
  role,
}: {
  academicEditorLabel?: string
  triageEditorLabel?: string
  role: string
}): string => {
  const editorShortForms: Record<string, string> = {
    'Chief Editor': 'CE',
    'Section Editor': 'SE',
    'Lead Guest Editor': 'LGE',
    'Academic Editor': 'AE',
    'Associate Editor': 'AssE',
    'Guest Editor': 'GE',
    'Handling Editor': 'HE',
    'Triage Editor': 'TE',
  }

  if (role === ROLES.AE && academicEditorLabel) {
    return editorShortForms[academicEditorLabel] || 'AE'
  }

  if (role === ROLES.TE && triageEditorLabel) {
    return editorShortForms[triageEditorLabel] || 'TE'
  }

  const defaultShortForms = {
    [ROLES.ADMIN]: 'Admin',
    [ROLES.AUTHOR]: 'AUT',
    [ROLES.REVIEWER]: 'Reviewer',
    [ROLES.EA]: 'EA',
    [ROLES.TE]: 'TE',
    [ROLES.AE]: 'AE',
  }

  return defaultShortForms[role] || role
}
