import React, { useMemo } from 'react'
import ReviewerReportTimeframe from './ReviewerReportTimeframe'
import { StatusTag } from '@shared/ui'
import { useReviewerReportExtensions } from '../../../../../../component-peer-review/client/components/peerReviewComponents/ReviewerInvitationPanel/hooks/useReviewerReportExtensions'
import { useCurrentUser } from '../../../../../../component-authentication/client'
import { ManuscriptStatus } from '@shared/ui/types'

type RespondedReviewer = {
  id: string
  responded: string
  status: string
  submitReportExtensions?: unknown[]
  userId?: string
  user?: {
    id: string
  }
}

type StatusTagWithReviewerTimeframeProps = {
  reviewers: RespondedReviewer[] | null | undefined
  peerReviewEditorialMapping:
    | {
        peerReviewEditorialModel?: {
          reviewer?: {
            submitReportTimeFrame: unknown
          }
          triageEditor?: {
            label: unknown
          }
          academicEditor?: {
            label: unknown
          }
          [key: string]: unknown
        }
        [key: string]: unknown
      }
    | null
    | undefined
  manuscriptId: string
  manuscriptVisibleStatus: string
  manuscriptStatus: string
  manuscriptVersion: string
}

const useCurrentUserReviewer = (
  respondedReviewers: RespondedReviewer[],
  currentUserId: string,
  reviewersDeadlineExtensions: Record<string, unknown>,
): RespondedReviewer | undefined => {
  return useMemo(() => {
    const currentReviewing = respondedReviewers.find(
      (r) =>
        (r.userId || r.user?.id) === currentUserId &&
        ['accepted', 'overdue'].includes(r.status),
    )

    if (!currentReviewing) return undefined

    const updatedReviewer: RespondedReviewer = {
      ...currentReviewing,
      submitReportExtensions:
        (reviewersDeadlineExtensions[currentReviewing.id] as unknown[]) || [],
    }

    return updatedReviewer
  }, [respondedReviewers, currentUserId, reviewersDeadlineExtensions])
}

const _StatusTagWithReviewerTimeframe = ({
  reviewers = [],
  peerReviewEditorialMapping,
  manuscriptId,
  manuscriptVisibleStatus,
  manuscriptStatus,
  manuscriptVersion,
}: StatusTagWithReviewerTimeframeProps) => {
  const respondedReviewers = (reviewers || []).filter((r) => !!r.responded)
  const { id: currentUserId } = useCurrentUser()

  const { reviewersDeadlineExtensions } = useReviewerReportExtensions(
    manuscriptId,
    respondedReviewers,
  )

  const currentUserReviewing = useCurrentUserReviewer(
    respondedReviewers,
    currentUserId,
    reviewersDeadlineExtensions,
  )

  const reviewerReportTimeFrame =
    peerReviewEditorialMapping?.peerReviewEditorialModel?.reviewer
      ?.submitReportTimeFrame

  return (
    <>
      {currentUserReviewing && (
        <ReviewerReportTimeframe
          reviewer={currentUserReviewing}
          reviewerReportTimeFrame={reviewerReportTimeFrame}
        />
      )}
      <StatusTag
        visibleStatus={manuscriptVisibleStatus}
        status={manuscriptStatus as ManuscriptStatus}
        version={manuscriptVersion}
      />
    </>
  )
}

export const StatusTagWithReviewerTimeframe = React.memo(
  _StatusTagWithReviewerTimeframe,
)
