import { useFetching } from '@hindawi/ui'
import { createAnchorElement, removeAnchorElement } from '../fileUtils'
import { getAuthTokens } from '../../../component-authentication/client/utils'

interface UseZipDownloadProps {
  manuscriptId: string
  archiveName?: string
}

interface UseZipDownloadReturn {
  downloadZip: () => Promise<void>
  isFetching: boolean
  fetchingError: string | null
}

const setCookie = (name, value, minutes) => {
  const date = new Date()
  date.setTime(date.getTime() + minutes * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  document.cookie = `${name}=${value}; ${expires}; path=/downloadZipFile`
}

export const useZipDownload = ({
  manuscriptId,
  archiveName,
}: UseZipDownloadProps): UseZipDownloadReturn => {
  const { isFetching, setFetching, fetchingError, setError } = useFetching()

  const downloadZip = async () => {
    setFetching(true)

    try {
      const { idToken } = getAuthTokens()
      setCookie('idToken', idToken, 10)
      const response = await fetch(`/downloadZipFile/${manuscriptId}`)
      setFetching(false)
      if (response.ok) {
        const fileName = archiveName || `${manuscriptId}-archive.zip`
        const blob = await response.blob()
        const blobFile = new Blob([blob], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blobFile)

        const { a } = createAnchorElement(url, fileName, true)
        a.click()
        removeAnchorElement(a, fileName)
      } else {
        throw new Error('Something went wrong downloading the zip file')
      }
    } catch (err: any) {
      setFetching(false)
      setError(err.name ?? err.message)
      console.error(err)
    }
  }

  return { downloadZip, isFetching, fetchingError }
}
