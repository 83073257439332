// TODO: consider replacing some of the logic found here with the use of an established lib (e.g. date-fns)

import {
  InputDate,
  DurationResult,
  DateObject,
  DurationBase,
  FormatType,
} from './types'

export const SECONDS_IN_MINUTE = 60
export const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24

export const isDateToday = (inputDate: InputDate): boolean => {
  if (!inputDate) {
    return false
  }

  const date = new Date(inputDate)
  const today = new Date()

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

const humanizeConstructor =
  ({
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
    inThePast,
  }: {
    years: number
    months: number
    days: number
    hours: number
    minutes: number
    seconds: number
    inThePast: boolean
  }) =>
  () => {
    const suffix = inThePast ? ' ago' : ''
    switch (true) {
      case years > 0:
        return `${years} ${years > 1 ? 'years' : 'year'}${suffix}`
      case months > 0:
        return `${months} ${months > 1 ? 'months' : 'month'}${suffix}`
      case days > 0:
        return `${days} ${days > 1 ? 'days' : 'day'}${suffix}`
      case hours > 0:
        return `${hours} ${hours > 1 ? 'hours' : 'hour'}${suffix}`
      case minutes > 0:
        return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}${suffix}`
      case seconds > 0:
        return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}${suffix}`
      default:
        return 'Just now'
    }
  }

export const isDateInTheFuture = (inputDate: InputDate): boolean => {
  if (!inputDate) {
    return false
  }

  const futureDate = new Date(inputDate)
  const today = new Date()

  return futureDate > today
}

export const isDateInThePast = (inputDate: InputDate): boolean => {
  if (!inputDate) {
    return false
  }

  const pastDate = new Date(inputDate)
  const today = new Date()

  return pastDate < today
}

export const areDatesEqual = (
  previousDate: InputDate,
  currentDate: InputDate,
): boolean => {
  if (!previousDate || !currentDate) {
    return false
  }
  const prevDate = new Date(previousDate)
  const currDate = new Date(currentDate)

  return (
    prevDate.getDate() === currDate.getDate() &&
    prevDate.getMonth() === currDate.getMonth() &&
    prevDate.getFullYear() === currDate.getFullYear()
  )
}

export const postprocessDate = (date: string): string => {
  const [month, day, year, time] = date
    .replace(/,/g, '')
    .replace('at', '')
    .split(' ')
    .filter((a) => a)

  return `${day} ${month} ${year} ${time ?? ''}`.trim()
}

const getFormatOptions = (
  formatType: FormatType,
): Intl.DateTimeFormatOptions => {
  switch (formatType) {
    case FormatType.NORMAL:
      return {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    case FormatType.LONG:
      return {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    case FormatType.LONG_WITH_DAY:
      return {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    case FormatType.LONG_NO_TIME:
      return {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      }
    case FormatType.SHORT:
      return {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }
    default:
      throw new Error('Invalid format type')
  }
}

export const getDateWithReviewFormat = (
  date: Date,
  formatType: FormatType,
): string => {
  const options: Intl.DateTimeFormatOptions = getFormatOptions(formatType)

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

  return formattedDate
}

export const getDuration = (
  startDate: InputDate,
  endDate?: InputDate,
): DurationResult => {
  const start = new Date(startDate)
  const end = endDate ? new Date(endDate) : new Date()

  const timeDiff =
    typeof startDate === 'string' || startDate instanceof Date
      ? end.valueOf() - start.valueOf()
      : startDate
  const absDiffInSeconds = Math.abs(Math.floor(timeDiff / 1000))

  const seconds = Math.floor(absDiffInSeconds) % 60
  const minutes = Math.floor(absDiffInSeconds / 60) % 60
  const hours = Math.floor(absDiffInSeconds / 60 / 60) % 24
  const days = Math.floor(absDiffInSeconds / 60 / 60 / 24) % 30
  const months = Math.floor(absDiffInSeconds / 60 / 60 / 24 / 30) % 12
  const years = Math.floor(absDiffInSeconds / 60 / 60 / 24 / 30 / 12)

  return {
    humanize: humanizeConstructor({
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
      inThePast: timeDiff < 0,
    }),
    asDays: () => Math.floor(timeDiff / 1000 / 60 / 60 / 24),
    asMilliseconds: () => timeDiff,
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  }
}

export const getDynamicDuration = (
  fromDate: Date,
  toDate: InputDate | DateObject,
) => {
  if (
    typeof toDate === 'string' ||
    typeof toDate === 'number' ||
    toDate instanceof Date
  ) {
    return getDuration(fromDate, toDate)
  }

  const { years, months, days, hours, minutes, seconds } = toDate

  const newDate = new Date(fromDate.valueOf())
  newDate.setFullYear(newDate.getFullYear() + (years ?? 0))
  newDate.setMonth(newDate.getMonth() + (months ?? 0))
  newDate.setDate(newDate.getDate() + (days ?? 0))
  newDate.setHours(newDate.getHours() + (hours ?? 0))
  newDate.setMinutes(newDate.getMinutes() + (minutes ?? 0))
  newDate.setSeconds(newDate.getSeconds() + (seconds ?? 0))

  return getDuration(fromDate, newDate)
}

export const getTimeAgo = ({ timestamp }) => {
  if (!timestamp) {
    return ''
  }

  const duration = getDuration(new Date(), timestamp)
  return duration.humanize()
}

export const getTimeStampToRender = ({
  timestamp,
  humanizeThreshold = 0,
  format,
}: {
  format?: FormatType
  timestamp: string
  humanizeThreshold: number
}) => {
  if (!timestamp) return ''
  const duration = getDuration(timestamp)

  if (duration.asDays() < humanizeThreshold) {
    return `${duration.humanize()}`
  }

  return getDateWithReviewFormat(
    new Date(timestamp),
    format ?? FormatType.SHORT,
  )
}

export const convertValueIntoUnit = (diff: number, unit: DurationBase) => {
  switch (unit) {
    case 'milliseconds':
    case 'millisecond':
    case 'ms':
      return diff
    case 'seconds':
    case 'second':
    case 's':
      return Math.floor(diff / 1000)
    case 'minutes':
    case 'minute':
    case 'm':
      return Math.floor(diff / 1000 / 60)
    case 'hours':
    case 'hour':
    case 'h':
      return Math.floor(diff / 1000 / 60 / 60)
    case 'days':
    case 'day':
    case 'd':
      return Math.floor(diff / 1000 / 60 / 60 / 24)
    case 'months':
    case 'month':
    case 'M':
      return Math.floor(diff / 1000 / 60 / 60 / 24 / 30)
    case 'years':
    case 'year':
    case 'y':
      return Math.floor(diff / 1000 / 60 / 60 / 24 / 30 / 12)
    default:
      return diff
  }
}

export const addBusinessDays = function (inputDate: InputDate, days: number) {
  const date = new Date(inputDate)
  let day = date.getDay()
  let isWeekend = day === 6 || day === 0
  if (isWeekend) {
    date.setDate(date.getDate() + 1)
  }
  while (days > 0) {
    date.setDate(date.getDate() + 1)
    day = date.getDay()
    isWeekend = day === 6 || day === 0
    if (!isWeekend) {
      days -= 1
    }
  }
  return date
}

const isAfter = function (sourceDate: InputDate, date: InputDate): boolean {
  return new Date(sourceDate) > new Date(date)
}

const isBefore = function (sourceDate: InputDate, date: InputDate): boolean {
  return new Date(sourceDate) < new Date(date)
}

const isBetween = function (
  sourceDate: InputDate,
  from: InputDate,
  to: InputDate,
): boolean {
  return (
    new Date(sourceDate) > new Date(from) && new Date(sourceDate) < new Date(to)
  )
}

const duration = function (
  fromDate: InputDate,
  toDate: InputDate | DateObject,
) {
  return getDynamicDuration(new Date(fromDate), toDate)
}

export const addDays = function (inputDate: InputDate, days: number): Date {
  const date = new Date(inputDate)
  date.setDate(date.getDate() + days)
  return date
}

export const addHours = function (inputDate: InputDate, hours: number): Date {
  const date = new Date(inputDate)
  date.setHours(date.getHours() + hours)
  return date
}

export const addWeeks = function (inputDate: InputDate, weeks: number): Date {
  const date = new Date(inputDate)
  date.setDate(date.getDate() + weeks * 7)
  return date
}

export const addMinutes = function (
  inputDate: InputDate,
  minutes: number,
): Date {
  const date = new Date(inputDate)
  date.setMinutes(date.getMinutes() + minutes)
  return date
}

export const addMonths = function (inputDate: InputDate, months: number): Date {
  const date = new Date(inputDate)
  date.setMonth(date.getMonth() + months)
  return date
}

export const addYears = function (inputDate: InputDate, years: number): Date {
  const date = new Date(inputDate)
  date.setFullYear(date.getFullYear() + years)
  return date
}

export const addSeconds = function (
  inputDate: InputDate,
  seconds: number,
): Date {
  const date = new Date(inputDate)
  date.setSeconds(date.getSeconds() + seconds)
  return date
}

export const addMilliseconds = function (
  inputDate: InputDate,
  milliseconds: number,
): Date {
  const date = new Date(inputDate)
  date.setMilliseconds(date.getMilliseconds() + milliseconds)
  return date
}

const add = function (
  inputDate: InputDate,
  value: number,
  unit: DurationBase,
): Date {
  const date = new Date(inputDate)
  switch (unit) {
    case 'milliseconds':
    case 'millisecond':
    case 'ms':
      return addMilliseconds(date, value)
    case 'seconds':
    case 'second':
    case 's':
      return addSeconds(date, value)
    case 'minutes':
    case 'minute':
    case 'm':
      return addMinutes(date, value)
    case 'hours':
    case 'hour':
    case 'h':
      return addHours(date, value)
    case 'days':
    case 'day':
    case 'd':
      return addDays(date, value)
    case 'weeks':
    case 'week':
    case 'w':
      return addWeeks(date, value)
    case 'months':
    case 'month':
    case 'M':
      return addMonths(date, value)
    case 'years':
    case 'year':
    case 'y':
      return addYears(date, value)
    default:
      return date
  }
}

const diff = function (
  inputDate: InputDate,
  date: InputDate,
  unit: DurationBase,
) {
  const diff = new Date(inputDate).valueOf() - new Date(date).valueOf()
  return convertValueIntoUnit(diff, unit)
}

const format = function (inputDate: InputDate, formatType?: FormatType) {
  if (!inputDate) return ''
  return getDateWithReviewFormat(
    new Date(inputDate),
    formatType ?? FormatType.SHORT,
  )
}

function getNextFriday(currentDate: Date) {
  const daysUntilFriday = (5 - currentDate.getDay() + 7) % 7
  const nextFridayDate = new Date(currentDate)
  nextFridayDate.setDate(currentDate.getDate() + daysUntilFriday)
  return nextFridayDate
}

const isoWeekday = (inputDate: InputDate) => {
  const date = new Date(inputDate)

  const day = date.getDay()

  if (day !== 5) {
    return getNextFriday(date)
  }
  return date
}

const humanizeTimeInterval = ({
  milliseconds,
}: {
  milliseconds: number
}): { value: number; unit: DurationBase } => {
  const y = convertValueIntoUnit(milliseconds, 'years')
  const d = convertValueIntoUnit(milliseconds, 'days')
  const h = convertValueIntoUnit(milliseconds, 'hours')
  const m = convertValueIntoUnit(milliseconds, 'minutes')
  const s = convertValueIntoUnit(milliseconds, 'seconds')

  if (y > 0) {
    return { value: y, unit: 'years' }
  }
  if (d > 0) {
    return { value: d, unit: 'days' }
  }
  if (h > 0) {
    return { value: h, unit: 'hours' }
  }
  if (m > 0) {
    return { value: m, unit: 'minutes' }
  }
  if (s > 0) {
    return { value: s, unit: 'seconds' }
  }
  return { value: milliseconds, unit: 'milliseconds' }
}

const getReviewerTimeUnit = (): DurationBase =>
  (process.env.REMINDER_REVIEWER_INVITATION_TIME_UNIT || 'days') as DurationBase

const getReviewerTimeConstants = (
  reviewerReportTimeFrame?: number | undefined,
) => ({
  reminderRemoveReviewer: Number(process.env.REMINDER_REMOVE_REVIEWER) || 8,
  reminderOverdueAcceptedReviewer: reviewerReportTimeFrame
    ? Number(reviewerReportTimeFrame)
    : 14,
  timeUnit: getReviewerTimeUnit(),
  businessDays: false, // disabled, because in the previous implementation it was not taken into account
})

const DateService = {
  duration,
  addBusinessDays,
  format,
  isAfter,
  isBefore,
  isBetween,
  add,
  diff,
  isoWeekday,
  getNextFriday,
  getTimeAgo,
  getTimeStampToRender,
  humanizeTimeInterval,
  getReviewerTimeConstants,
  getReviewerTimeUnit,
  SECONDS_IN_DAY,
  SECONDS_IN_MINUTE,
}

export const getExpectedDate = ({
  timestamp = Date.now(),
  daysExpected = 0,
  format,
}: {
  timestamp?: InputDate
  daysExpected: number
  format: FormatType
}) => {
  return DateService.format(
    DateService.add(timestamp, daysExpected, 'days'),
    format,
  )
}

export { DateService }
