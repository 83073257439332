import React from 'react'
import ReactDOM from 'react-dom'
import { Row } from '@hindawi/ui'
import styled from 'styled-components'
import { IconClose } from '@hindawi/phenom-ui'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalRoot = styled(Row)`
  position: relative;
  width: 567px;
  border-radius: 4px;
  box-shadow: 3px 4px 10px 1px rgba(51, 51, 51, 0.18);
  background-color: white;
`

const ModalContent = styled.div`
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #4f4f4f;
`

export const Modal = ({ children, toggle }) => {
  const handleClickOutside = (event) => {
    event.stopPropagation()
    toggle()
  }
  const handleClickInside = (event) => event.stopPropagation()

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleClickOutside}>
      <ModalRoot flexDirection="column" onClick={handleClickInside} p={10}>
        <ModalContent>
          <IconClose onClick={toggle} />
        </ModalContent>
        {children}
      </ModalRoot>
    </ModalOverlay>,
    document.getElementById('ps-modal-root'),
  )
}
