import type { Identity, Role } from '@shared/ui/types'
import { createContext, useContext } from 'react'

type UserContext = {
  id: string
  isActive: boolean
  role: Role
  identities: Identity[]
  pendingInvitation: {
    id: string
  } | null
  hasViewerPermission: boolean | null
  isSuperAdmin: boolean
  hasAdministrativeRole: boolean
  roles: string[]
  connectId?: string
}

const INITIAL_USER_CONTEXT: UserContext = {
  id: '',
  isActive: false,
  role: 'user',
  identities: [],
  pendingInvitation: null,
  hasViewerPermission: null,
  isSuperAdmin: false,
  hasAdministrativeRole: false,
  roles: [],
}

const CurrentUserContext = createContext<UserContext>(INITIAL_USER_CONTEXT)

export function useCurrentUser(): UserContext {
  return useContext(CurrentUserContext)
}

export default CurrentUserContext
