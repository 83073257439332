import { ManuscriptStatus } from '@shared/ui/types'

// These are statuses that are considered final from the Editor's/Reviewer's perspective.
export const FINALIZED_STATUSES: ManuscriptStatus[] = [
  'qualityChecksRequested',
  'qualityChecksSubmitted',
  'accepted',
  'rejected',
  'withdrawn',
  'published',
  'void',
  'deleted',
  'olderVersion',
  'refusedToConsider',
  'inQA',
  'revisionRequested',
]
