import { Row, Tag } from '@hindawi/phenom-ui'
import {
  getStatusPriority,
  StatusPriority,
} from '../../../component-dashboard/client/components/ManuscriptCard/utils'
import React, { ReactNode } from 'react'
import { ManuscriptStatus } from '@shared/ui/types'

export function StatusTag({
  visibleStatus,
  status,
  version,
}: {
  visibleStatus: string
  status: ManuscriptStatus
  version: string
}): ReactNode {
  const statusPriority: StatusPriority = getStatusPriority(status)

  return (
    <Row>
      <Tag
        left-data-test-id="manuscript-status"
        right-data-test-id="manuscript-current-version"
        leftLabel={visibleStatus}
        label={`v${version}`}
        priority={statusPriority}
      />
    </Row>
  )
}
