import { useParams } from 'react-router-dom'

type RouteParams = {
  manuscriptId?: string
  submissionId?: string
}

type ReturnParams = {
  manuscriptId: string
  submissionId: string
}

export function useManuscriptDetailsParams(): ReturnParams {
  const { manuscriptId, submissionId } = useParams<RouteParams>()

  if (!manuscriptId || !submissionId) {
    throw new Error(
      'Manuscript ID or Submission ID is missing, might be calling this hook outside of the ManuscriptDetails route',
    )
  }

  return { manuscriptId, submissionId }
}
