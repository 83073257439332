import React, { useState } from 'react'
import { get } from 'lodash'
import { AuthorTag, AuthorTagWithTooltip, Superscript } from './components'
import { parseAffiliations } from '@shared/ui/utils'
import {
  Button,
  Flex,
  IconCollapse,
  IconExpand,
  Row,
  Space,
  Text,
  Tooltip,
} from '@hindawi/phenom-ui'
import { Author } from '@shared/ui/types'

type AuthorTagListProps = {
  authors: Author[]
  withTooltip?: boolean
  withAffiliations?: boolean
  withEmailCopyTooltip?: boolean
  hasStaffRole?: boolean
}

const AUTHOR_KEY = 'id'

export function AuthorTagList({
  authors,
  withTooltip = false,
  withAffiliations = false,
  withEmailCopyTooltip = false,
  hasStaffRole = false,
}: AuthorTagListProps): React.JSX.Element {
  const [showAffiliation, setShowAffiliation] = useState<boolean>(false)

  const toggleAffiliation = () => setShowAffiliation(!showAffiliation)

  const parsedAffiliations = parseAffiliations(authors)
  const affiliationList =
    withAffiliations && get(parsedAffiliations, 'affiliations', [])

  authors = withAffiliations ? get(parsedAffiliations, 'authors', []) : authors

  return (
    <Flex direction="column" gap={0}>
      <Flex data-test-id="author-tag-list" wrap="wrap" gap={0}>
        {authors
          .map((author) => {
            if (withTooltip)
              return (
                <AuthorTagWithTooltip
                  author={author}
                  key={get(author, AUTHOR_KEY, '')}
                />
              )
            if (withEmailCopyTooltip)
              return (
                <Tooltip
                  copyContent={author.alias.email}
                  showCopyContent
                  replaceTooltipText="Email"
                  key={get(author, AUTHOR_KEY, '')}
                >
                  <div data-test-id={`author-tag-list-with-copy-${author.id}`}>
                    <AuthorTag
                      author={author}
                      key={get(author, AUTHOR_KEY, '')}
                      hasStaffRole={hasStaffRole}
                    />
                  </div>
                </Tooltip>
              )
            return (
              <AuthorTag author={author} key={get(author, AUTHOR_KEY, '')} />
            )
          })
          .reduce((acc: React.ReactNode[], element, i) => {
            if (i && i < authors.length) {
              acc.push(
                <Flex key={`comma-${i}`} mr={4}>
                  ,
                </Flex>,
              )
            }
            acc.push(element)
            return acc
          }, [])}

        {withAffiliations && (
          <Button
            type="tertiary"
            data-test-id="author-tag-list-affiliations-toggle"
            onClick={toggleAffiliation}
          >
            {showAffiliation ? <IconCollapse /> : <IconExpand />}
            {showAffiliation ? 'Hide Affiliations' : 'Show Affiliations'}
          </Button>
        )}
      </Flex>

      {withAffiliations && showAffiliation && affiliationList && (
        <Row data-test-id="author-tag-list-affiliations">
          <Space direction="vertical" size={8}>
            {affiliationList.map((aff, i) => (
              <Row data-test-id={`affiliation-${i + 1}`} key={aff}>
                <Text>{aff}</Text>
                <Superscript>{i + 1}</Superscript>
              </Row>
            ))}
          </Space>
        </Row>
      )}
    </Flex>
  )
}
