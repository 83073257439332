import { useEffect } from 'react'

const adobeAnalyticsId = 'adobe-analytics-script-id'

function isValidUrl(string: string) {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}

const AdobeAnalytics = () => {
  useEffect(() => {
    const adobeAnalyticsSrc = process.env.ADOBE_ANALYTICS_SRC
    if (!adobeAnalyticsSrc || !isValidUrl(adobeAnalyticsSrc)) return

    const scriptSrc = adobeAnalyticsSrc
    const existingScript = document.getElementById(adobeAnalyticsId)

    if (existingScript) {
      if (existingScript.getAttribute('src') === scriptSrc) return // Already loaded, do nothing
      existingScript.remove() // Remove if the src is different
    }

    const adobeScript = document.createElement('script')
    adobeScript.src = scriptSrc
    adobeScript.async = true
    adobeScript.id = adobeAnalyticsId

    document.head.appendChild(adobeScript)

    return () => {
      adobeScript.remove()
    }
  }, [])

  return null
}

export default AdobeAnalytics
