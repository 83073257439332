import { Identity } from '@shared/ui/types'
import { useCurrentUser } from '../../component-authentication/client'
import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
} from 'react'

import { useScript } from 'usehooks-ts'

interface ChatbotContextType {
  setManuscriptData: (customId: string) => void
  readyToSetData: boolean
  closeChatbot: () => void
  openChatbot: () => void
}

const ChatbotContext = createContext<ChatbotContextType | undefined>(undefined)

export const ChatbotContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const isChatbotEnabled = process.env.CHATBOT_ENABLED
  const scriptUrl = process.env.CHATBOT_SCRIPT_URL
  const bootstrapUrl = process.env.CHATBOT_BOOTSTRAP_URL

  const [isReady, setIsReady] = useState(false)
  const currentUser = useCurrentUser()
  const scriptStatus = useScript(scriptUrl!, {
    shouldPreventLoad: !isChatbotEnabled,
  })
  const bootstrapStatus = useScript(bootstrapUrl!, {
    shouldPreventLoad: !isChatbotEnabled,
  })

  const localIdentity = currentUser?.identities?.find(
    (identity) => identity.__typename === 'Local',
  )

  const readyToSetData = useMemo(() => {
    return isReady && !!currentUser
  }, [isReady, currentUser])

  const setManuscriptData = (customId: string) => {
    window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
      EditorialReferenceCode: customId,
      ConnectID: currentUser?.connectId,
    })
  }

  const setPersonalData = (identity?: Identity) => {
    window.embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields({
      _firstName: {
        value: identity?.name?.givenNames,
        isEditableByEndUser: false,
      },
      _lastName: {
        value: identity?.name?.surname,
        isEditableByEndUser: false,
      },
      _email: {
        value: identity?.email,
        isEditableByEndUser: true,
      },
    })
    window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
      ConnectID: currentUser?.connectId,
    })
  }

  const closeChatbot = () => {
    if (isChatbotEnabled) {
      window.embeddedservice_bootstrap.utilAPI.hideChatButton()
    }
  }
  const openChatbot = () => {
    if (isChatbotEnabled) {
      window.embeddedservice_bootstrap.utilAPI.showChatButton()
    }
  }
  useEffect(() => {
    if (isReady && currentUser && localIdentity) {
      setPersonalData(localIdentity)
    }
  }, [isReady, currentUser])

  useEffect(() => {
    if (isChatbotEnabled) {
      // sync chatbot status with react state
      window.addEventListener('onEmbeddedMessagingReady', () => {
        setPersonalData(localIdentity)
        setIsReady(true)
      })
    }
  }, [localIdentity])

  useEffect(() => {
    if (bootstrapStatus === 'ready' && scriptStatus === 'ready') {
      window.initEmbeddedMessaging()
    }
  }, [bootstrapStatus, scriptStatus])

  return (
    <ChatbotContext.Provider
      value={{ setManuscriptData, readyToSetData, closeChatbot, openChatbot }}
    >
      {children}
    </ChatbotContext.Provider>
  )
}

export const useChatbotContext = (): ChatbotContextType => {
  const context = useContext(ChatbotContext)
  if (!context) {
    throw new Error('useChatbot must be used within a ChatbotProvider')
  }
  return context
}

export const useManuscriptChatbotData = (customId: string): void => {
  const { setManuscriptData, readyToSetData } = useChatbotContext()
  useEffect(() => {
    if (customId && readyToSetData) {
      setManuscriptData(customId)
    }
  }, [customId, readyToSetData])
}
