import React, { useCallback, useRef, useEffect, useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import {
  IconCircleCheck,
  IconNotificationError,
  Spinner,
  Text,
} from '@hindawi/phenom-ui'
import { autosaveState } from '../graphql/queries'
import { updateAutosave } from '../graphql/mutations'

export const AutosaveIndicator = ({
  delay = 3000,
  successText = 'Progress Saved',
  errorText = 'Changes not saved',
  progressText = 'Saving changes...',
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const timerRef = useRef(null)
  const { data } = useQuery(autosaveState, { pollInterval: 1000 })
  const autosave = data?.autosave || {
    inProgress: false,
    error: '',
    updatedAt: undefined,
  }

  const prevAutosaveRef = useRef(autosave)

  const showIndicator = () => setIsVisible(true)
  const hideIndicator = () => setIsVisible(false)

  const setTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(hideIndicator, delay)
  }

  useEffect(() => {
    if (!isEqual(prevAutosaveRef.current, autosave)) {
      showIndicator()
      setTimer()
    }
    prevAutosaveRef.current = autosave
  }, [autosave])

  useEffect(() => {
    setTimer()
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  if (!isVisible) {
    return <span />
  }

  const { inProgress, error, updatedAt } = autosave

  return (
    <Root>
      {!inProgress && !updatedAt && (
        <AutoSaveContainer>
          <Spinner icon="loader" size={4} />
          <Text>{progressText}</Text>
        </AutoSaveContainer>
      )}
      {!inProgress && updatedAt && (
        <AutoSaveContainer>
          <IconContainer>
            <IconCircleCheck style={{ color: '#81ba40' }} />
          </IconContainer>
          <Text>{successText}</Text>
        </AutoSaveContainer>
      )}
      {!inProgress && error && (
        <AutoSaveContainer>
          <IconContainer>
            <IconNotificationError />
          </IconContainer>
          <Text type="danger" title={error}>
            {errorText}
          </Text>
        </AutoSaveContainer>
      )}
    </Root>
  )
}

const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`
const AutoSaveContainer = styled.div`
  align-items: center;
  display: flex;
  padding: calc(4px * 2);
`
const IconContainer = styled.div`
  margin-right: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
`
