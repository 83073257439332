import { gql } from '@apollo/client'

export const confirmUser = gql`
  mutation confirmUser($input: ConfirmUserInput!) {
    confirmAccount(input: $input) {
      token
    }
  }
`

export const updateAffiliation = gql`
  mutation updateAffiliation($input: UpdateAffiliationInput!) {
    updateAffiliation(input: $input)
  }
`
