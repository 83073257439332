import { get, chain, find } from 'lodash'

export const handleError = (fn) => (e) => {
  fn(get(JSON.parse(e.response), 'error', 'Oops! Something went wrong!'))
}

export const getReportComments = ({ report, type = 'public' }) =>
  chain(report)
    .get('comments', [])
    .find((c) => c.type === type)
    .get('content')
    .value()

//TODO use shared ui instead
/**
 * @deprecated use getShortRole from shared-ui instead
 */
export const getShortRole = ({
  role,
  academicEditorLabel,
  triageEditorLabel,
}) => {
  const roles = {
    admin: 'Admin',
    author: 'Author',
    reviewer: 'Reviewer',
    triageEditor: triageEditorLabel,
    academicEditor: academicEditorLabel,
    editorialAssistant: 'Editorial Assistant',
  }

  const tags = [
    { tag: 'Admin', role: 'Admin' },
    { tag: 'EA', role: 'Editorial Assistant' },
    { tag: 'CE', role: 'Chief Editor' },
    { tag: 'SE', role: 'Section Editor' },
    { tag: 'LGE', role: 'Lead Guest Editor' },
    { tag: 'AE', role: 'Academic Editor' },
    { tag: 'AssE', role: 'Associate Editor' },
    { tag: 'GE', role: 'Guest Editor' },
    { tag: 'SA', role: 'Author' },
    { tag: 'Reviewer', role: 'Reviewer' },
    { tag: 'HE', role: 'Handling Editor' },
  ]

  const roleLabel = roles[role]
  const roleTag = tags.find((tag) => tag.role === roleLabel)

  return roleTag && roleTag.tag
}

export const trimFormStringValues = (values) =>
  Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string' ? value.trim() : value,
    }),
    {},
  )

export const parseSearchParams = (url) => {
  const params = new URLSearchParams(url)
  const parsedObject = {}
  /* eslint-disable */
  for (let [key, value] of params) {
    parsedObject[key] = value
  }
  /* eslint-enable */
  return parsedObject
}
