import { Flex, FlexProps } from '@hindawi/phenom-ui'
import React from 'react'

export function ToolTipContainer({
  children,
  align,
  minWidth = 236,
  maxHeight = 272,
}: {
  children: React.ReactNode
  minWidth?: FlexProps['minWidth']
  maxHeight?: FlexProps['maxHeight']
  align?: FlexProps['align']
}): React.JSX.Element {
  return (
    <Flex
      px={'@spacer-3'}
      pt={'@spacer-3'}
      mb={'@spacer-3'}
      gap={5}
      direction="column"
      maxHeight={maxHeight}
      minWidth={minWidth}
      overflowY="auto"
      align={align}
    >
      {children}
    </Flex>
  )
}
