import React, { ReactNode } from 'react'
import { get } from 'lodash'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Title, Text, Flex } from '@hindawi/phenom-ui'
import { WhiteBox } from '@shared/ui'
function InfoPage(): ReactNode {
  const navigate = useNavigate()
  const { location } = window

  const pathState = get(location, 'state', {}) as {
    title: string
    content: string
    path: string
    buttonText: string
  }

  if (!pathState.title) {
    return <Navigate replace to="/" />
  }
  return (
    <WhiteBox
      justify="space-between"
      direction="column"
      align="center"
      textAlign="center"
    >
      <Title level={4}>{pathState.title}</Title>
      <Flex width="full" mt={2} justify="center">
        <Text>{pathState.content}</Text>
      </Flex>

      {pathState.path && (
        <Flex width="full" mt={4} justify="center">
          <Button onClick={() => navigate(pathState.path)} type="primary">
            {pathState.buttonText}
          </Button>
        </Flex>
      )}
    </WhiteBox>
  )
}

export default InfoPage
