import { ManuscriptStatus } from '@shared/ui/types'

export enum BinCategory {
  ActionRequired = 'Action required',
  InProgress = 'In progress',
  Finalized = 'Finalized',
  All = 'All',
}

export enum Permission {
  Editor = 'Assigned manuscripts',
  Viewer = 'All manuscripts',
}

type OnChangeState<T> = (T) => void

export type BinKeys = keyof typeof BinCategory
export type PermissionKeys = keyof typeof Permission

export type ManuscriptStatusMap = Omit<
  Record<BinKeys, ManuscriptStatus[]>,
  'All'
>

export interface StatusWithFilter {
  visibleStatus: string
  statusFilter: string
  count: number
}

export type DynamicStatus = StatusWithFilter | string

export type BinWithCount = {
  [Key in BinKeys]: number
}

export type PermissionWithCount = {
  [Key in PermissionKeys]: number
}

export type PermissionsInfo = {
  [Key in PermissionKeys]: string
}

export type ManuscriptDropDownStatus = ManuscriptStatus | 'All'

export type ManuscriptDropDownStatuses = ManuscriptDropDownStatus[]

export enum OrderCategory {
  UPDATED_DATE_OLDEST_FIRST = 'Last Updated (oldest)',
  UPDATED_DATE_NEWEST_FIRST = 'Last Updated (newest)',
  SUBMISSION_DATE_OLDEST_FIRST = 'Last Submitted (oldest)',
  SUBMISSION_DATE_NEWEST_FIRST = 'Last Submitted (newest)',
}

interface PaginatedLazyQueryI {
  getCountByBin: () => BinWithCount
  getCountByManuscriptPermission: () => PermissionWithCount
  chosenOrder: OrderCategory
  onChangeOrder: OnChangeState<OrderCategory>
  onChangeStatus: OnChangeState<ManuscriptDropDownStatus>
  onChangeBin: OnChangeState<BinCategory>
  onChangeManuscriptPermission: OnChangeState<Permission>
  bin: BinKeys
  manuscriptPermission: PermissionKeys
  getStatuses: () => DynamicStatus[]
  chosenStatus: ManuscriptDropDownStatus
  loading: boolean
  showResults: boolean
  totalManuscripts: number
  handleChangePage: (number) => void
  page: number
  resultsTopDescription: string
  manuscripts: any[] //Put any by design, Dashboard should not be aware of Card Domain
  hasViewerPermission: boolean | null
}

export type PaginatedLazyQuery = Required<PaginatedLazyQueryI>

export type StorageSelection = {
  chosenOrder: OrderCategory
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export const permissionMapping: Map<PermissionKeys, Permission> = new Map<
  PermissionKeys,
  Permission
>([
  ['Editor', Permission.Editor],
  ['Viewer', Permission.Viewer],
])
