import React from 'react'
import styled from 'styled-components'
import Fade from './Fade'
import { IconDownloadZip, Spinner, Text, Flex } from '@hindawi/phenom-ui'
import { useZipDownload } from '../decorators'

const DownloadZip = ({ manuscript, ...rest }) => {
  const { isFetching, downloadZip, fetchingError } = useZipDownload({
    manuscriptId: manuscript.id,
    archiveName: `ID-${manuscript.customId || manuscript.id}.zip`,
  })

  return (
    <Root>
      {isFetching ? (
        <Spinner size="xsmall" />
      ) : (
        <Flex fontSize={16}>
          <IconDownloadZip onClick={downloadZip} />
        </Flex>
      )}
      {fetchingError && (
        <Fade>
          <ErrorWrapper>
            <Text type="danger">{fetchingError}</Text>
          </ErrorWrapper>
        </Fade>
      )}
    </Root>
  )
}

export default DownloadZip

// #region styles
const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const ErrorWrapper = styled.div`
  position: absolute;
  top: calc(4px * 3);
  left: 0;

  width: calc(4px * 20);
`
// #endregion
