import { useFetching } from '@hindawi/ui'

import { createAnchorElement, removeAnchorElement } from '../fileUtils'
import { useMutation } from '@apollo/client'
import { getSignedUrl as GET_SIGNED_URL_MUTATION } from '../graphql/mutations'

export const useFileDownload = (file) => {
  const { isFetching, setFetching, fetchingError, setError } =
    useFetching(false)
  const [getSignedUrl] = useMutation(GET_SIGNED_URL_MUTATION)

  const downloadFile = () => {
    setFetching(true)

    getSignedUrl({ variables: { fileId: file.id } })
      .then((response) => fetch(response.data.getSignedUrl))
      .then(async (response) => {
        if (response.ok) {
          return response.blob()
        } else {
          const err = await response.text()
          throw new Error(err)
        }
      })
      .then((fileBlob) => {
        const { a, url } = createAnchorElement(fileBlob, file.filename)
        a.click()
        removeAnchorElement(a, url)
      })
      .catch((err) => {
        setError(err.name ?? err.message)
        console.error(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }

  return { downloadFile, isFetching, fetchingError }
}
