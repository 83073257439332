import { IconLock, Text } from '@hindawi/phenom-ui'
import React from 'react'
import { LABEL } from '../../constants/labels'

export function NotVisibleToAuthorsAndReviewers(): React.JSX.Element {
  return (
    <Text type="success" style={{ color: '#74A73A' }}>
      <IconLock /> {LABEL.NOT_VISIBLE_TO_AUTHORS_AND_REVIEWERS}
    </Text>
  )
}
