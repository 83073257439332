import { useQuery } from '@apollo/client'
import { getReviewersReportDueDateExtensions } from '../../../../graphql/queries'
import { useMemo } from 'react'

export const useReviewerReportExtensions = (
  manuscriptId: string,
  respondedReviewers,
) => {
  // we should call for getting reviewers due date extension
  // only if there are at least one reviewer who has responded to the invitation
  const { data: reviewerReportExtensions, loading: loadingReviewerExtensions } =
    useQuery(getReviewersReportDueDateExtensions, {
      variables: { manuscriptId: manuscriptId },
      skip: !respondedReviewers.length,
    })

  const reviewersDueDateExtensionsList =
    reviewerReportExtensions?.getReviewersReportDueDateExtensions

  const reviewersDeadlineExtensions = useMemo(
    () =>
      (reviewersDueDateExtensionsList || []).reduce(
        (mappedExtensions, extension) => {
          const { reviewerTeamMemberId } = extension
          if (!mappedExtensions[reviewerTeamMemberId]) {
            mappedExtensions[reviewerTeamMemberId] = []
          }
          mappedExtensions[reviewerTeamMemberId].push(extension)
          return mappedExtensions
        },
        {},
      ),
    [reviewersDueDateExtensionsList],
  )

  return {
    loadingReviewerExtensions,
    reviewersDeadlineExtensions,
  }
}
