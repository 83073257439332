import React from 'react'
import { useMutation } from '@apollo/client'

import * as queries from './queries'
import * as mutations from './mutations'

const withConfirmUserMutation = (WrappedComponent) => {
  const WithConfirmUserMutation = (props) => {
    const [
      confirmUser,
      { loading: confirmUserLoading, error: confirmUserError },
    ] = useMutation(mutations.confirmUser, {
      refetchQueries: [
        {
          query: queries.currentUser,
        },
      ],
    })

    return (
      <WrappedComponent
        {...props}
        confirmUser={confirmUser}
        confirmUserLoading={confirmUserLoading}
        confirmUserError={confirmUserError}
      />
    )
  }
  return WithConfirmUserMutation
}

export default withConfirmUserMutation
