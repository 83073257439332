import { Flex, FlexProps } from '@hindawi/phenom-ui'
import React from 'react'
import styled from 'styled-components'

export type WhiteBoxProps = {
  className?: string
  children: React.ReactNode
} & FlexProps

const WhiteAreaFlex = ({ className, children, ...rest }: WhiteBoxProps) => (
  <Flex
    className={className}
    width="inherit"
    p={rest.p ?? 24}
    gap={rest.gap ?? 24}
    height={rest.height ?? 'full'}
    {...rest}
  >
    {children}
  </Flex>
)

const WhiteArea = styled(WhiteAreaFlex)`
  background-color: var(--color-neutral-white);
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.11);
`

export function WhiteBox({
  children,
  ...rest
}: WhiteBoxProps): React.JSX.Element {
  return <WhiteArea {...rest}>{children}</WhiteArea>
}
