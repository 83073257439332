import { gql } from '@apollo/client'

export const teamMember = gql`
  fragment teamMember on TeamMember {
    id
    isSubmitting
    isCorresponding
    invited
    responded
    reviewerNumber
    invitationSource
    user {
      id
    }
    status
    alias {
      aff
      email
      country
      affRorId
      name {
        surname
        givenNames
      }
    }
    conflictsOfInterest {
      id
      alias {
        aff
        country
        email
        name {
          surname
          givenNames
        }
      }
    }
  }
`

export const teamMemberWithIdentities = gql`
  fragment teamMemberWithIdentities on TeamMember {
    id
    isSubmitting
    isCorresponding
    invited
    responded
    reviewerNumber
    user {
      id
      identities {
        ... on External {
          identifier
        }
        ... on Local {
          name {
            surname
          }
        }
      }
    }
    status
    alias {
      aff
      email
      country
      affRorId
      name {
        surname
        givenNames
      }
    }
    conflictsOfInterest {
      id
      alias {
        aff
        country
        email
        name {
          surname
          givenNames
        }
      }
    }
  }
`

export const specialIssueFragment = gql`
  fragment specialIssue on SpecialIssue {
    id
    name
    isCancelled
    endDate
  }
`

export const sectionFragment = gql`
  fragment section on Section {
    id
    name
  }
`

export const fileFragment = gql`
  fragment manuscriptDetailsFile on File {
    id
    type
    size
    filename
    originalName
    mimeType
    designation
  }
`

export const articleTypeFragment = gql`
  fragment articleTypeDetails on ArticleType {
    id
    name
  }
`

export const reviewDetails = gql`
  fragment reviewDetails on Review {
    id
    isSelected
    sourceJournalId
    member {
      role
      user {
        id
      }
      alias {
        name {
          surname
          givenNames
        }
      }
      reviewerNumber
    }
    created
    comments {
      id
      type
      content
      files {
        ...manuscriptDetailsFile
      }
    }
    recommendation
    rejectDecisionInfo {
      reasonsForRejection {
        rejectReason
        otherReasons
      }
      transferRecommendations {
        name
        homepage
      }
      messageForDestinationEditors
    }
    open
    submitted
    wantsPublonsRecognition
    reviewerAnswers
  }
  ${fileFragment}
`

export const manuscriptFragment = gql`
  fragment manuscriptDetails on Manuscript {
    id
    status
    submissionId
    preprintValue
    journalId
    submissionId
    created
    updated
    submittedDate
    visibleStatus
    inProgress
    submissionSystem
    hasSpecialIssueEditorialConflictOfInterest
    hasTriageEditorConflictOfInterest
    isEditable
    sourceJournalId
    commissionInvitationId
    keywords
    waiverApplied {
      type
      reason
      otherReason
    }
    peerReviewModel {
      name
      hasTriageEditor
      approvalEditors
      hasFigureheadEditor
      figureheadEditorLabel
      academicEditorLabel
      triageEditorLabel
    }
    journal {
      ... on Journal {
        id
        name
        publisherName
        code
        enablePkgSuggestions
        enablePkgEditorSuggestions
        enableJournalLevelReviewerSearch
        hasPublonsReviewerRecognitionEnabled
        preprintDescription
        preprints {
          type
          format
        }
        publisherName
        waiverCount
        revenueModel
        goaWaiverable
      }
    }
    triageEditor {
      ...teamMember
    }
    isApprovalEditor
    hasTEConfirmedRevision
    specialIssue {
      id
      section {
        name
      }
    }
    authors {
      id
      isSubmitting
      isCorresponding
      alias {
        aff
        affRorId
        name {
          surname
          givenNames
        }
        email
        country
        title
      }
    }
    meta {
      title
      abstract
      dataAvailability
      fundingStatement
      conflictOfInterest
      submissionQuestions
      reviewerPdfComments
    }
    version
    technicalCheckToken
    role
    customId
    articleType {
      ...articleTypeDetails
    }
    files {
      ...manuscriptDetailsFile
    }
    specialIssue {
      ...specialIssue
    }
    section {
      ...section
    }
    reviews {
      ...reviewDetails
    }
    academicEditor {
      ...teamMember
    }
    editorialAssistant {
      ...teamMember
    }
    academicEditors {
      ...teamMember
    }
    pendingAcademicEditor {
      ...teamMember
    }
    reviewers {
      ...teamMember
      reviewerStatusLabel
    }
    manuscriptProductionComments {
      id
      productionCommentId
      productionComment
      userId
    }

    statusColor
    editorDecisions
    peerReviewEditorialMapping {
      id
      created
      updated
      submissionId
      peerReviewEditorialModel {
        name
        majorRevisionDeadline
        minorRevisionDeadline
        triageEditor {
          label
          enabled
        }
        academicEditor {
          label
          labelInCaseOfConflict
          autoInviteInCaseOfConflict
        }
        approvalEditor {
          enabled
          label
          decisionEditorMustApproveRevisionRequest
        }
        figureHeadEditor {
          label
          enabled
        }
        reviewerQuestions {
          name
          type
          title
          isRequired
          description
          showOtherItem
          choices {
            text
            value
          }
        }
        reviewer {
          submitReportTimeFrame
          minimumReports
        }
        customReviewerQuestionsLink
        peerReviewModel
      }
    }
    revisionDeadlineDate
    currentUserRole
    IsEditorOnJournalEditorialBoard
  }
  ${sectionFragment}
  ${specialIssueFragment}
  ${teamMember}
  ${fileFragment}
  ${reviewDetails}
  ${articleTypeFragment}
`

export const userFragment = gql`
  fragment manuscriptDetailsUser on User {
    id
    isActive
    role
    identities {
      ... on Local {
        name {
          surname
          givenNames
          title
        }
        email
        aff
        isConfirmed
        country
      }
    }
  }
`

export const SuggestedJournalFragment = gql`
  fragment ReferralJournal on SuggestedJournal {
    id
    name
    homepage
    impactFactor
    publicationOption
    inNetwork
    keywords
  }
`
