import React, { useEffect, useRef, useState } from 'react'
import { FileCard, message } from '@hindawi/phenom-ui'
import { useMutation, useQuery } from '@apollo/client'

import { useFileDownload } from '../decorators'
import { fileHasPreview } from '../fileUtils'

import {
  deleteFile as DELETE_FILE_MUTATION,
  getSignedUrl as GET_SIGNED_URL_MUTATION,
} from '../graphql/mutations'
import { getFileScanStatus as GET_FILE_SCAN_STATUS_QUERY } from '../graphql/queries'

import {
  ANTIVIRUS_SCAN_POLLING_INTERVAL,
  ANTIVIRUS_TIMEOUT_POLLING_INTERVAL,
  ANTIVIRUS_TIMEOUT_POLLING_RETRY_COUNT,
} from './constants'
import { FileCardProps, FileStatus } from '@hindawi/phenom-ui/dist/FileCard'

const FileScanStatuses = Object.freeze({
  SKIPPED: 'skipped',
  SCANNING: 'scanning',
  INFECTED: 'infected',
  HEALTHY: 'healthy',
  TIMEOUT_PASSED: 'timeoutPassed',
  ERROR: 'error',
})

const FileLayoutWithScanning = ({
  file,
  removeItemFromForm,
  setIsScanning = () => {},
}: {
  file: { filename: string; id: string; size: number }
  removeItemFromForm: () => void
  setIsScanning: (arg: boolean) => void
}) => {
  const { filename, id, size } = file
  const timerID = useRef(null)

  const [status, setStatus] = useState({
    previousStatus: null,
    currentStatus: null,
  })

  const {
    data: fileScanData,
    loading: fileScanDataLoading,
    error: fileScanError,
    startPolling,
    stopPolling,
  } = useQuery(GET_FILE_SCAN_STATUS_QUERY, {
    variables: {
      fileId: id,
    },
  })

  const { downloadFile } = useFileDownload(file)
  const [getSignedUrl] = useMutation(GET_SIGNED_URL_MUTATION)
  const [deleteFile] = useMutation(DELETE_FILE_MUTATION)

  const onPreview = async () => {
    try {
      const response = await getSignedUrl({ variables: { fileId: id } })
      const { getSignedUrl: signedUrl } = response.data

      window.open(signedUrl)
    } catch (e) {
      message.error({ content: `Error fetching preview: ${e.message}` })
    }
  }

  const onDelete = async () => {
    try {
      await deleteFile({ variables: { fileId: id } })
      removeItemFromForm()
    } catch (e) {
      message.error({ content: `Error deleting file: ${e.message}` })
    }
  }

  const cleanupPolling = (timerID) => {
    clearTimeout(timerID)
    stopPolling()
  }

  useEffect(() => {
    const { scanStatus } = fileScanData?.getFileInfo || {}
    const isScanning = scanStatus === FileScanStatuses.SCANNING

    setIsScanning(isScanning)

    if (isScanning) {
      startPolling(ANTIVIRUS_SCAN_POLLING_INTERVAL)
    }

    if (scanStatus === FileScanStatuses.TIMEOUT_PASSED) {
      startPolling(ANTIVIRUS_TIMEOUT_POLLING_INTERVAL)
      // @ts-ignore
      timerID.current = setTimeout(
        stopPolling,
        ANTIVIRUS_TIMEOUT_POLLING_INTERVAL *
          ANTIVIRUS_TIMEOUT_POLLING_RETRY_COUNT,
      )
    }

    if (fileScanError && scanStatus === FileScanStatuses.TIMEOUT_PASSED) {
      cleanupPolling(timerID.current)
    }

    if (scanStatus) {
      setStatus((status) => ({
        previousStatus: status.currentStatus,
        currentStatus: scanStatus,
      }))
    }

    return () => {
      cleanupPolling(timerID.current)
    }
  }, [fileScanData, fileScanError])

  if (fileScanDataLoading) {
    return (
      <FileCard
        file={{
          name: filename,
          uid: id,
          size,
          status: FileStatus.UPLOADING,
          statusCustomMessage: 'Fetching scan status',
        }}
        withButtons={false}
        withWhiteBg
      />
    )
  }

  const { scanStatus } = fileScanData.getFileInfo
  const isPreviewVisible = fileHasPreview(file)

  const cardProps: FileCardProps = {
    file: {
      name: filename,
      uid: id,
      size,
      status: FileStatus.UPLOADING,
      statusCustomMessage: '',
    },
    onRemove: onDelete,
    onPreview: onPreview,
    onDownload: downloadFile,
    withButtons: true,
    withRetry: false,
    withPreview: isPreviewVisible,
    withWhiteBg: true,
  }

  switch (scanStatus) {
    case FileScanStatuses.SCANNING:
      cardProps.file.status = FileStatus.UPLOADING
      cardProps.file.statusCustomMessage = 'Scanning for viruses'
      cardProps.withButtons = false
      cardProps.onRemove = undefined
      return <FileCard {...cardProps} />
    case FileScanStatuses.ERROR:
      cardProps.file.status = FileStatus.ERROR
      cardProps.file.statusCustomMessage = 'Scan failed! Retry scan.'
      return <FileCard {...cardProps} />
    case FileScanStatuses.INFECTED:
      cardProps.file.status = FileStatus.ERROR
      cardProps.file.statusCustomMessage =
        'File is infected! Please choose another file.'
      return <FileCard {...cardProps} />
  }

  if (
    [FileScanStatuses.SCANNING, FileScanStatuses.TIMEOUT_PASSED].includes(
      // @ts-ignore
      status.previousStatus,
    ) &&
    [FileScanStatuses.TIMEOUT_PASSED, FileScanStatuses.HEALTHY].includes(
      // @ts-ignore
      status.currentStatus,
    )
  ) {
    cardProps.file.statusCustomMessage = 'Uploaded'
    cardProps.file.status = FileStatus.SUCCESS
    return <FileCard {...cardProps} />
  }

  cardProps.file.status = FileStatus.EXISTING
  return <FileCard {...cardProps} />
}

export default FileLayoutWithScanning
