import React from 'react'
import { compose, lifecycle, withState } from 'recompose'

import { useJournal } from '../../../component-journal-info'
import { Button, Spinner, Title, Text, Flex } from '@hindawi/phenom-ui'

import { parseSearchParams } from '../utils'
import withAuthenticationGQL from '../graphql'
import { useNavigate } from 'react-router-dom'
import { WhiteBox } from '@shared/ui'

const loading = `Loading...`
const confirmSubtitle = `Your account has been successfully confirmed.`
const errorTitle = `Something went wrong. Please try again.`

const ConfirmAccount = ({ message: { title, subtitle } }) => {
  const navigate = useNavigate()
  const { name } = useJournal()
  const confirmTitle = `Welcome to ${name}!`
  return (
    <WhiteBox justify="space-between" direction="column" align="center">
      <Flex textAlign="center" width="full" justify="center">
        <Title level={4}>
          {title !== errorTitle ? confirmTitle : errorTitle}
        </Title>
      </Flex>
      <Flex
        width="full"
        mb={title !== errorTitle ? 2 : 0}
        mt={2}
        justify="center"
      >
        <Text>{subtitle}</Text>
      </Flex>
      {title !== errorTitle && (
        <Flex width="full" align="center" justify="center">
          {title === loading ? (
            <Spinner />
          ) : (
            <Button onClick={() => navigate('/')} type="primary">
              Go to Dashboard
            </Button>
          )}
        </Flex>
      )}
    </WhiteBox>
  )
}

export default compose(
  withAuthenticationGQL,
  withState('message', 'setConfirmMessage', {
    title: loading,
  }),
  lifecycle({
    componentDidMount() {
      const { confirmUser, setConfirmMessage } = this.props
      const { confirmationToken, userId } = parseSearchParams(
        document.location.search,
      )
      confirmUser({
        variables: {
          input: {
            userId,
            token: confirmationToken,
          },
        },
      })
        .then(() => {
          setConfirmMessage({
            subtitle: confirmSubtitle,
          })
        })
        .catch((err) => {
          setConfirmMessage({
            title: errorTitle,
            subtitle: err.message,
          })
        })
    },
  }),
)(ConfirmAccount)
