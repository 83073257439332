import React, { useState } from 'react'
import { formatTimeToSubmitOrOverdue } from '../../../../../../component-reviewer-suggestions/client/components/utils'
import {
  ValidationMessage,
  ValidationMessageType,
  Text,
  Popover,
} from '@hindawi/phenom-ui'

const ReviewerReportTimeframe = ({ reviewer, reviewerReportTimeFrame }) => {
  const [tooltipText, setTooltipText] = useState('')
  const [textContent, setTextContent] = useState('')
  const [statusType, setStatusType] = useState('')

  const timeframeElement = React.useMemo(() => {
    const timeFormatWithStatus = formatTimeToSubmitOrOverdue(
      reviewer,
      reviewerReportTimeFrame,
    )
    let text = ''

    switch (timeFormatWithStatus.showStatusAs) {
      case 'accepted':
        text = `${timeFormatWithStatus.formattedTime} to submit`
        setTextContent(text)
        setStatusType('accepted')
        return (
          <Text>
            <span style={{ color: 'var(--color-primary-blue-100)' }}>
              {text}
            </span>
          </Text>
        )
      case 'overdue':
        text = timeFormatWithStatus.formattedTime.replace('ago', 'overdue')
        setTextContent(text)
        setStatusType('overdue')
        return (
          <ValidationMessage
            type={ValidationMessageType.Error}
            message={text}
          ></ValidationMessage>
        )
      default:
        setTextContent('')
        setStatusType('')
        return null
    }
  }, [reviewer, reviewerReportTimeFrame])

  React.useEffect(() => {
    if (statusType === 'accepted') {
      setTooltipText(`
        <p>You have ${textContent} the review report.</p>
        <p>If you are unable to submit your report in the available time, please contact the journal and request a deadline extension.</p>
      `)
    } else if (statusType === 'overdue') {
      setTooltipText(`
        <p>Your report is ${textContent}.</p>
        <p>Once a recommendation is made, you will no longer be able to submit your report.</p>
        <p>If you still indent to submit, please contact the journal to request a deadline extension so the editors are aware of your willingness to complete the report.</p>
      `)
    } else {
      setTooltipText('')
    }
  }, [textContent, statusType])

  return (
    <>
      {timeframeElement && (
        <div
          style={{
            display: 'inlineBlock',
            position: 'absolute',
            right: '11rem',
            minWidth: '12rem',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              float: 'right',
            }}
          >
            <Popover
              placement="top"
              arrowPointAtCenter
              content={
                <aside
                  style={{
                    padding: '.75rem .5rem 0',
                  }}
                  dangerouslySetInnerHTML={{ __html: tooltipText }}
                ></aside>
              }
            >
              {timeframeElement}
            </Popover>
          </div>
        </div>
      )}
    </>
  )
}

export default ReviewerReportTimeframe
