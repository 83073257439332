import { SUBMISSION_SYSTEM } from '@shared/ui/constants'

export type PublisherName = 'HINDAWI' | 'WILEY'

export type SubmissionSystem =
  (typeof SUBMISSION_SYSTEM)[keyof typeof SUBMISSION_SYSTEM]

export type ManuscriptStatus =
  | 'draft'
  | 'technicalChecks'
  | 'submitted'
  | 'academicEditorInvited'
  | 'academicEditorAssigned'
  | 'reviewersInvited'
  | 'underReview'
  | 'reviewCompleted'
  | 'revisionRequested'
  | 'revisionSubmitted'
  | 'revisionChecks'
  | 'pendingApproval'
  | 'rejected'
  | 'inQA'
  | 'accepted'
  | 'withdrawn'
  | 'void'
  | 'deleted'
  | 'published'
  | 'olderVersion'
  | 'academicEditorAssignedEditorialType'
  | 'makeDecision'
  | 'qualityChecksRequested'
  | 'qualityChecksSubmitted'
  | 'refusedToConsider'

interface PeerReviewModel {
  name: string
  hasTriageEditor: boolean
  approvalEditors: string[]
  hasFigureheadEditor: boolean
  figureheadEditorLabel: string | null
  academicEditorLabel: string | null
  triageEditorLabel: string | null
}

export enum InvitationSource {
  REVIEWER_SUGGESTIONS = 'reviewerSuggestions',
  REVIEWER_SEARCH = 'reviewerSearch',
  REVIEWER_INVITED_MANUALLY = 'reviewerInvitedManually',
}

export type ReviewerStatusLabel =
  | 'OVERDUE'
  | 'UNRESPONSIVE'
  | 'UNSUBSCRIBED'
  | 'PENDING'
  | 'ACCEPTED'
  | 'DECLINED'
  | 'SUBMITTED'
  | 'EXPIRED'
  | 'REMOVED'
  | 'ACTIVE'
  | 'CONFLICTING'
  | 'REVOKED'

interface Journal {
  id: string
  name: string
  publisherName: PublisherName
  code: string
  enablePkgSuggestions: boolean
  enablePkgEditorSuggestions: boolean
  enableJournalLevelReviewerSearch: boolean
  hasPublonsReviewerRecognitionEnabled: boolean
  preprintDescription: string | null
  preprints: Preprint[]
  waiverCount: number | null
  revenueModel: string | null
  goaWaiverable: string
}

interface Preprint {
  type: string
  format: string
}

// TODO - Store models should not be identitically reflected on ui, we should apply presentational types like Reviewer AcademicEditor
export interface TeamMember {
  id: string
  isSubmitting: boolean | null
  isCorresponding: boolean | null
  invited: string | null
  responded: string | null
  reviewerNumber: number | null
  invitationSource: InvitationSource | null
  user: User | null
  status: string
  alias: Alias | null
  conflictsOfInterest: string | null
  reviewerStatusLabel?: ReviewerStatusLabel
}

interface User {
  id: string
}

interface Alias {
  aff: string | null
  email: string | null
  country: string | null
  affRorId: string | null
  name: Name | null
}

interface Name {
  surname: string
  givenNames: string
}

interface ManuscriptMeta {
  title: string
  abstract: string
  dataAvailability: string | null
  fundingStatement: string | null
  conflictOfInterest: string | null
  submissionQuestions: string | null
}

interface ArticleType {
  id: string
  name: string
}

interface FileEntry {
  id: string
  type: string
  size: number
  filename: string
  originalName: string
  mimeType: string
  designation: string
}

export interface Review {
  id: string
  isSelected: boolean
  sourceJournalId: string | null
  member: TeamMember
  created: string
  comments: CommentEntry[]
  recommendation: string | null
  rejectDecisionInfo: string | null
  open: boolean | null
  submitted: string | null
  wantsPublonsRecognition: WantsRecognition
  reviewerAnswers: ReviewerAnswers | null
}

export type WantsRecognition = 'yes' | 'no' | 'not implemented'

export interface ReviewerReportI extends Review {
  reviewerNumber: number
  currentUserReview?: any
}

interface CommentEntry {
  id: string
  type: string
  content: string | null
  files: any[]
}

interface ReviewerAnswers {
  id: string
  reviewId: string
  values: {
    [key: string]: {
      order: number
      answer: string
      metadata: {
        isHiddenFromAuthors: boolean
      }
      question: string
    }
  }
  created: string
  updated: string
}

interface PeerReviewEditorialMapping {
  id: string
  created: string
  updated: string
  submissionId: string
  peerReviewEditorialModel: PeerReviewEditorialModel
}

interface PeerReviewEditorialModel {
  name: string
  triageEditor: TriageEditor
  academicEditor: AcademicEditor
  approvalEditor: ApprovalEditor
  figureHeadEditor: FigureHeadEditor
  reviewerQuestions: ReviewerQuestion[]
  reviewer: ReviewerConfig
  customReviewerQuestionsLink: string | null
  peerReviewModel: string
  majorRevisionDeadline: number
  minorRevisionDeadline: number
}

interface TriageEditor {
  label: string
  enabled: boolean
}

interface AcademicEditor {
  label: string
  labelInCaseOfConflict: string
  autoInviteInCaseOfConflict: boolean
}

interface ApprovalEditor {
  enabled: boolean
  label: string
  decisionEditorMustApproveRevisionRequest: boolean
}

interface FigureHeadEditor {
  label: string | null
  enabled: boolean
}

export interface ReviewerQuestion {
  name: string
  type: string
  title: string
  isRequired: boolean
  description: string | null
  showOtherItem: boolean | null
  choices: Choice[]
}

interface Choice {
  text: string
  value: string
}

interface ReviewerConfig {
  submitReportTimeFrame: string | null
  minimumReports: number
}

export interface Manuscript {
  id: string
  status: ManuscriptStatus
  submissionId: string
  preprintValue: string | null
  journalId: string
  created: string
  updated: string
  submittedDate: string
  visibleStatus: string
  inProgress: boolean | null
  submissionSystem: SubmissionSystem
  hasSpecialIssueEditorialConflictOfInterest: boolean
  hasTriageEditorConflictOfInterest: boolean
  isEditable: boolean
  sourceJournalId: string | null
  commissionInvitationId: string | null
  keywords: string | null
  waiverApplied: WaiverApplied | null
  peerReviewModel: PeerReviewModel
  journal: Journal
  triageEditor: TeamMember | null
  isApprovalEditor: boolean
  hasTEConfirmedRevision: boolean | null
  specialIssue: string | null
  authors: TeamMember[]
  meta: ManuscriptMeta
  version: string
  technicalCheckToken: string | null
  role: string
  customId: string
  articleType: ArticleType
  files: FileEntry[]
  section: string | null
  reviews: Review[]
  academicEditor: TeamMember | null
  editorialAssistant: TeamMember | null
  academicEditors: TeamMember[]
  pendingAcademicEditor: TeamMember | null
  reviewers: TeamMember[]
  manuscriptProductionComments: string | null
  statusColor: string
  editorDecisions: string[]
  peerReviewEditorialMapping: PeerReviewEditorialMapping
  revisionDeadlineDate: string | null
  currentUserRole: string
  IsEditorOnJournalEditorialBoard: boolean

  hasAcceptOption: boolean
}

export type Version = {
  version: string
  id: string
}

export type WaiverApplied = {
  type: WaiverType
  reason: string | null
  otherReason: string | null
} | null

export enum WaiverType {
  WAIVER_CODE = 'waiverCode',
  APC_OVERRIDE = 'apcOverride',
}
