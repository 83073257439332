import React from 'react'
import { useMutation } from '@apollo/client'

import * as mutations from './mutations'

export const withSignedUrl = (WrappedComponent) => {
  const WithSignedUrl = (props) => {
    const [getSignedUrlMutation] = useMutation(mutations.getSignedUrl)

    const getSignedUrl = (fileId) => {
      return getSignedUrlMutation({
        variables: { fileId },
      })
    }

    return <WrappedComponent {...props} getSignedUrl={getSignedUrl} />
  }

  return WithSignedUrl
}

export default (options) => (WrappedComponent) => {
  const WithFilesGql = (props) => {
    const [uploadFileMutation] = useMutation(mutations.uploadFile, options)
    const [deleteFileMutation] = useMutation(mutations.deleteFile, options)
    const [getUploadSignedUrlMutation] = useMutation(
      mutations.getUploadSignedUrl,
      options,
    )

    const deleteFile = (fileId) => deleteFileMutation({ variables: { fileId } })
    const uploadFile = ({ entityId, fileInput, providerKey }) =>
      uploadFileMutation({
        variables: { entityId, fileInput, providerKey },
      }).then((r) => r.data.uploadFile)
    const getUploadSignedUrl = (entityId) =>
      getUploadSignedUrlMutation({ variables: { entityId } }).then(
        (r) => r.data.getUploadSignedUrl,
      )

    return (
      <WrappedComponent
        {...props}
        deleteFile={deleteFile}
        uploadFile={uploadFile}
        getUploadSignedUrl={getUploadSignedUrl}
      />
    )
  }
  return WithFilesGql
}
