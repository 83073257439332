import React from 'react'
import { Row } from '@hindawi/ui'
import { Modal } from './Modal'
import { Button, Flex, Preset, Title, Text } from '@hindawi/phenom-ui'

// TODO - To be removed
export const InformationModal = ({ icon, title, error, toggle }) => (
  <Modal toggle={toggle}>
    <Row alignItems="start">
      {icon}
      <Flex alignItems="start" direction="column" gap={'@spacer-1'}>
        <Title preset={Preset.PRIMARY}>{title}</Title>
        {error && <Text type="danger">{error}</Text>}
      </Flex>
    </Row>
    <Row mt={12}>
      <Button onClick={toggle} type="primary">
        OK
      </Button>
    </Row>
  </Modal>
)
