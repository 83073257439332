import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import HindawiApp from './HindawiApp'
import { ChatbotContextProvider } from './chatbotContext'

export const Root = ({
  routes,
  theme,
}: {
  routes: React.ReactNode
  theme: Record<string, any>
}): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <HindawiApp>
        <ChatbotContextProvider>
          <GlobalStyles />
          {routes}
        </ChatbotContextProvider>
      </HindawiApp>
    </ThemeProvider>
  )
}

const GlobalStyles = createGlobalStyle`
  ${normalize}

  body {
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-input-group-addon {
      background-color: var(--color-neutral-grey-70);
  }

  .ant-input--disabled .ant-input-group-addon {
      background-color: var(--color-neutral-grey-30);
  }

  .ant-input--disabled .ant-input-group-addon__cta {
      cursor: not-allowed;
      color: var(--color-neutral-grey-50);
      background-color: var(--color-neutral-grey-30);
  }

  .ant-anchor {
      cursor: pointer !important;
  }

  .ant-anchor--busy {
      cursor: wait !important;
  }

  .ant-popover-inner-content {
      width: fit-content !important;
      padding: 0 !important;
  }

  .ant-popover-inner {
      width: fit-content !important;
  }

  .ant-popover-placement-bottom .ant-popover-arrow {
      left: 155px !important;
  }

  .bulk-import-editor-button .anticon + span {
      display: none;
  }

  /* WebKit, Blink */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* FF */
  input[type="number"] {
      -moz-appearance: textfield;
  }

`
