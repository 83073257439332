import { gql } from '@apollo/client'

export const currentUser = gql`
  query {
    currentUser {
      id
      isActive
      role
      roles
      connectId
      pendingInvitation {
        id
        email
        type
        metadata
      }
      hasViewerPermission
      isSuperAdmin
      hasAdministrativeRole
      identities {
        ... on Local {
          name {
            givenNames
            surname
            title
          }
          isConfirmed
          email
        }
        ... on External {
          identifier
          email
          aff
        }
      }
    }
  }
`
