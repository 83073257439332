import React from 'react'
import { Col, Preset, Text } from '@hindawi/phenom-ui'
import { Delimiter } from '.'
import { useDateParser } from '@shared/ui/hooks'

export function DateCell({
  date,
  label,
}: Record<'date' | 'label', string>): React.JSX.Element {
  const { timestamp, timeAgo } = useDateParser({
    timestamp: date,
    humanizeThreshold: 0,
  })

  return (
    <>
      {date && (
        <div className="submitted-date">
          <Col>
            <Text preset={Preset.BOLD} className="bold">
              {label}:
            </Text>
          </Col>
          <Delimiter />
          <Text>{timestamp}</Text>
          <Delimiter />
          <Text>({timeAgo})</Text>
        </div>
      )}
    </>
  )
}
