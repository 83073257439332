import { get } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { getManuscripts, getViewerManuscripts } from '../../../graphql/queries'

import { useSessionStorage } from '@shared/ui/hooks'
import { useCurrentUser } from '../../../../../component-authentication/client/components/CurrentUserContext'
import {
  ALL_POSSIBLE_STATUSES,
  BIN_CATEGORY_FILTER_MAP,
  DEFAULT_ITEM_PER_PAGE,
  DEFAULT_STORAGE_SELECTION,
} from '../constants'
import {
  BinCategory,
  BinKeys,
  ManuscriptDropDownStatus,
  OrderCategory,
  PaginatedLazyQuery,
  Permission,
  PermissionKeys,
  StatusWithFilter,
  permissionMapping,
} from '../types'
import {
  addAllToStatuses,
  findVariablesForOrder,
  getCountByBin,
  getResultsTopDescription,
  getViewerCount,
} from '../utils'

export const usePaginatedLazyQuery = (): PaginatedLazyQuery => {
  const currentUser = useCurrentUser()
  const { hasViewerPermission } = currentUser

  const searchValue = ''
  const [page, setPage] = useState<number>(1)
  const [availableStatuses, setAvailableStatuses] = useState<
    StatusWithFilter[]
  >([])

  const [chosenOrder, setOrder] = useSessionStorage<OrderCategory>(
    'ChosenOrderFilter',
    DEFAULT_STORAGE_SELECTION.chosenOrder,
  )

  const [chosenStatus, setStatus] = useSessionStorage<ManuscriptDropDownStatus>(
    'ChosenStatusFilter',
    'All',
  )
  const [chosenBin, setBin] = useSessionStorage<BinKeys>(
    'ChosenBinFilter',
    'ActionRequired',
  )

  const [chosenPermission, setChosenPermission] =
    useState<PermissionKeys>('Editor')
  const [viewerAvailableStatuses, setViewerAvailableStatuses] = useState<
    StatusWithFilter[]
  >([])

  const onChangeOrder = (newOrderValue: OrderCategory) => {
    setOrder(newOrderValue)
    setPage(1)
  }

  const onChangeStatus = (newStatusValue: typeof chosenStatus) => {
    setStatus(newStatusValue)
    setPage(1)
  }

  const onChangeBin = (newBinValue: typeof chosenBin) => {
    setBin(newBinValue)
    setStatus('All')
    setPage(1)
  }

  const onChangePermission = (newValue: typeof chosenPermission) => {
    setChosenPermission(newValue)
    setPage(1)
  }

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const [getManuscriptsMutation, { data, loading, called }] = useLazyQuery(
    getManuscripts,
    { fetchPolicy: 'network-only' },
  )

  const manuscripts = get(data, 'getManuscripts.manuscripts', [])
  const totalManuscripts = get(data, 'getManuscripts.totalCount', 0)
  const statusWithFilter = get(data, 'getManuscripts.statusWithFilter', [])

  const [
    getViewerManuscriptsMutation,
    { data: viewerData, loading: viewerLoading, called: viewerCalled },
  ] = useLazyQuery(getViewerManuscripts, { fetchPolicy: 'network-only' })

  const viewerManuscripts = get(
    viewerData,
    'getViewerManuscripts.manuscripts',
    [],
  )
  const viewerTotalManuscripts: number = get(
    viewerData,
    'getViewerManuscripts.totalCount',
    0,
  )
  const viewerStatusWithFilter: StatusWithFilter[] = get(
    viewerData,
    'getViewerManuscripts.statusWithFilter',
    [],
  )

  const onSearch = ({
    statusesToSend,
    binFilter,
  }: {
    statusesToSend: string
    binFilter: string
  }) => {
    const getQueryInput = (chosenOrder) => {
      const orderVariable = findVariablesForOrder(chosenOrder)
      return {
        variables: {
          input: {
            submissionFilter: binFilter,
            searchValue: searchValue,
            statusFilter: statusesToSend,
            orderBy: orderVariable.orderBy,
            orderDirection: orderVariable.orderDirection,
            page: page - 1,
            pageSize: DEFAULT_ITEM_PER_PAGE,
          },
        },
      }
    }
    void getManuscriptsMutation(getQueryInput(chosenOrder))
  }

  function onSearchViewer(statusesToSend: string) {
    if (!hasViewerPermission) {
      return
    }
    const orderVariable = findVariablesForOrder(chosenOrder)
    const input = {
      variables: {
        input: {
          searchValue: searchValue,
          statusFilter: statusesToSend,
          orderBy: orderVariable.orderBy,
          orderDirection: orderVariable.orderDirection,
          page: page - 1,
          pageSize: DEFAULT_ITEM_PER_PAGE,
        },
      },
    }
    void getViewerManuscriptsMutation(input)
  }

  useEffect(() => {
    onSearch({
      statusesToSend: chosenStatus,
      binFilter: BIN_CATEGORY_FILTER_MAP[chosenBin],
    })
    onSearchViewer(chosenStatus)
  }, [])

  useEffect(() => {
    if (permissionMapping.get(chosenPermission) === Permission.Viewer) {
      onSearchViewer(chosenStatus)
    } else {
      onSearch({
        statusesToSend: chosenStatus,
        binFilter: BIN_CATEGORY_FILTER_MAP[chosenBin],
      })
    }
  }, [page, chosenOrder, chosenStatus])

  useEffect(() => {
    onSearch({
      statusesToSend: chosenStatus,
      binFilter: BIN_CATEGORY_FILTER_MAP[chosenBin],
    })
    setAvailableStatuses(statusWithFilter)
  }, [chosenBin])

  useEffect(() => {
    if (permissionMapping.get(chosenPermission) === Permission.Viewer) {
      onSearchViewer(chosenStatus)
    } else {
      onSearch({
        statusesToSend: chosenStatus,
        binFilter: BIN_CATEGORY_FILTER_MAP[chosenBin],
      })
    }
  }, [chosenPermission])

  useEffect(() => {
    if (
      !availableStatuses.length &&
      statusWithFilter &&
      statusWithFilter.length
    ) {
      const isPersistedStatusStillAvailable = statusWithFilter?.find(
        (elem) => elem?.visibleStatus == chosenStatus,
      )
      if (!isPersistedStatusStillAvailable) {
        setStatus('All')
      }
    }
    setAvailableStatuses(statusWithFilter)
  }, [data])

  useEffect(() => {
    if (!viewerAvailableStatuses.length && viewerStatusWithFilter) {
      setViewerAvailableStatuses(viewerStatusWithFilter)
    }
  }, [viewerData])

  const getStatuses = (): string[] => {
    if (
      permissionMapping.get(chosenPermission) === Permission.Editor &&
      !availableStatuses.length
    ) {
      return ALL_POSSIBLE_STATUSES
    }
    if (
      permissionMapping.get(chosenPermission) === Permission.Viewer &&
      !viewerAvailableStatuses.length
    ) {
      return ALL_POSSIBLE_STATUSES
    }
    if (permissionMapping.get(chosenPermission) === Permission.Viewer) {
      return addAllToStatuses([
        ...new Set(viewerAvailableStatuses.map((a) => a.visibleStatus)),
      ])
    }
    if (chosenBin === BinCategory.All) {
      return addAllToStatuses([
        ...new Set(availableStatuses.map((a) => a.visibleStatus)),
      ])
    }
    return addAllToStatuses([
      ...new Set(
        availableStatuses
          .filter(
            (statusWithFilter) =>
              statusWithFilter.statusFilter ===
              BIN_CATEGORY_FILTER_MAP[chosenBin],
          )
          .map((a) => a.visibleStatus),
      ),
    ])
  }

  const resultsTopDescription = useMemo(
    () =>
      getResultsTopDescription({
        total: totalManuscripts,
        page,
        pageSize: DEFAULT_ITEM_PER_PAGE,
        currentCount: manuscripts.length,
      }),
    [totalManuscripts, page, manuscripts.length],
  )

  const viewerResultsTopDescription = useMemo(
    () =>
      getResultsTopDescription({
        total: viewerTotalManuscripts,
        page,
        pageSize: DEFAULT_ITEM_PER_PAGE,
        currentCount: viewerManuscripts.length,
      }),
    [viewerTotalManuscripts, page, viewerManuscripts.length],
  )

  return {
    resultsTopDescription:
      permissionMapping.get(chosenPermission) === Permission.Viewer
        ? viewerResultsTopDescription
        : resultsTopDescription,
    getStatuses,
    handleChangePage,
    page,
    manuscripts:
      permissionMapping.get(chosenPermission) === Permission.Viewer
        ? viewerManuscripts
        : manuscripts,
    loading:
      permissionMapping.get(chosenPermission) === Permission.Viewer
        ? viewerLoading
        : loading,
    showResults:
      permissionMapping.get(chosenPermission) === Permission.Viewer
        ? viewerData && viewerCalled
        : data && called,
    getCountByBin: getCountByBin(availableStatuses),
    getCountByManuscriptPermission: getViewerCount(
      availableStatuses,
      viewerAvailableStatuses,
    ),
    totalManuscripts:
      permissionMapping.get(chosenPermission) === Permission.Viewer
        ? viewerTotalManuscripts
        : totalManuscripts,
    onChangeBin,
    onChangeManuscriptPermission: onChangePermission,
    chosenStatus,
    bin:
      permissionMapping.get(chosenPermission) === Permission.Viewer
        ? 'All'
        : chosenBin,
    manuscriptPermission: chosenPermission,
    chosenOrder,
    onChangeOrder,
    onChangeStatus,
    hasViewerPermission,
  }
}
