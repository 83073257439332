import { gql } from '@apollo/client'

import {
  draftManuscriptDetails,
  activeJournals,
  sourceJournals,
  submissionEditorialModel,
  submissionEditorialMapping,
} from './fragments'

export const getManuscriptAndActiveJournals = gql`
  query manuscript($manuscriptId: String!) {
    manuscript(manuscriptId: $manuscriptId) {
      ...draftManuscriptDetails
    }
    getActiveJournals(manuscriptId: $manuscriptId) {
      ...activeJournals
    }
  }
  ${draftManuscriptDetails}
  ${activeJournals}
`

export const getActiveJournals = gql`
  query getActiveJournals($manuscriptId: String) {
    getActiveJournals(manuscriptId: $manuscriptId) {
      ...activeJournals
    }
  }
  ${activeJournals}
`

export const autosaveState = gql`
  query autosaveState {
    autosave @client {
      error
      updatedAt
      inProgress
    }
  }
`

export const getSourceJournals = gql`
  query getSourceJournals {
    getSourceJournals {
      ...sourceJournals
    }
  }
  ${sourceJournals}
`

export const getPublishedManuscripts = gql`
  query getPublishedManuscripts(
    $input: String!
    $journalId: String!
    $sectionId: String
    $specialIssueId: String
  ) {
    getPublishedManuscripts(
      input: $input
      journalId: $journalId
      sectionId: $sectionId
      specialIssueId: $specialIssueId
    ) {
      id
      title
      customId
      journal {
        id
        name
      }
    }
  }
`
export const getManuscriptByCustomId = gql`
  query getManuscriptByCustomId($input: String!) {
    getManuscriptByCustomId(input: $input) {
      id
      title
      customId
      journal {
        id
        name
      }
    }
  }
`

export const getSubmissionEditorialModel = gql`
  query getSubmissionEditorialModel(
    $submissionId: String
    $journalId: String
    $issueType: String
    $articleType: String
    $isOnSection: Boolean
  ) {
    getSubmissionEditorialModel(
      submissionId: $submissionId
      journalId: $journalId
      issueType: $issueType
      articleType: $articleType
      isOnSection: $isOnSection
    ) {
      ...submissionEditorialModel
    }
  }
  ${submissionEditorialModel}
`

export const getSubmissionEditorialMapping = gql`
  query getSubmissionEditorialMapping($submissionId: String!) {
    getSubmissionEditorialMapping(submissionId: $submissionId) {
      ...submissionEditorialMapping
    }
  }
  ${submissionEditorialMapping}
`
