import { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export function useResizeObserver({
  selector = '#root main>div',
  handler,
}: {
  selector?: string
  handler: ResizeObserverCallback
}): void {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handler)
    const scrollContent = document.querySelector(selector)

    if (!scrollContent) {
      return
    }

    resizeObserver.observe(scrollContent)
    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.unobserve(scrollContent)
    }
  }, [])
}
