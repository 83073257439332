import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  gql,
  HttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { getAuthTokens } from '../../component-authentication/client/utils'
import { getCookieValue } from '../../component-peer-review/client/fingerprint'

// https://github.com/apollographql/apollo-client/blob/main/CHANGELOG.md#inmemorycache
// Apollo Client 3.0.0 introduces a breaking changes to the InMemoryCache API.
// With global.__DEV__ = true all cache results are frozen/immutable.
global.__DEV__ = false
export default function makeApolloClient() {
  const typeDefs = gql`
    extend type Mutation {
      updateAutosave: Boolean
    }
  `
  const authLink = setContext((_, { headers }) => {
    const { token } = getAuthTokens()
    const browserFingerprint = getCookieValue('BROWSER_FINGERPRINT')
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'browser-fingerprint': browserFingerprint ? browserFingerprint : '',
      },
    }
  })

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          autosave: {
            read(existing) {
              return existing
            },
          },
        },
      },
    },
  })

  // Initialize the cache with default state
  cache.writeQuery({
    query: gql`
      query GetAutosave {
        autosave @client {
          error
          inProgress
          updatedAt
        }
      }
    `,
    data: {
      autosave: {
        __typename: 'AutosaveState',
        error: null,
        inProgress: false,
        updatedAt: null,
      },
    },
  })

  const config = {
    link: ApolloLink.from([
      authLink,
      new HttpLink({
        credentials: 'include',
        uri: process.env.GATEWAY_URI,
      }),
    ]),
    cache: cache,
    typeDefs,
    resolvers: {
      Mutation: {
        updateAutosave: (_, { params }, { cache }) => {
          cache.modify({
            fields: {
              autosave(existingData = {}) {
                return {
                  __typename: 'AutosaveState',
                  ...existingData,
                  ...params,
                }
              },
            },
          })
          return {
            __typename: 'AutosaveState',
            ...params,
          }
        },
      },
    },
  }

  return new ApolloClient(config)
}
