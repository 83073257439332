import styled from 'styled-components'

import { Space } from '@hindawi/phenom-ui'
import { DateService, DurationBase } from '../../../component-date-service'

export const MIN_REVIEWER_TABLE_HEIGHT = '300px'

export const getDeadlineDate = () => {
  const { businessDays, reminderRemoveReviewer, timeUnit } =
    DateService.getReviewerTimeConstants()
  return businessDays
    ? DateService.addBusinessDays(
        new Date(),
        reminderRemoveReviewer,
      ).toISOString()
    : DateService.add(
        new Date(),
        reminderRemoveReviewer,
        timeUnit,
      ).toISOString()
}

export const formatTimeToRespond = (reviewer) => {
  if (!reviewer.invited) {
    return ''
  }

  const now = new Date()
  const { reminderRemoveReviewer, timeUnit } =
    DateService.getReviewerTimeConstants()
  const deadline = DateService.add(
    new Date(reviewer.invited),
    reminderRemoveReviewer,
    timeUnit,
  )

  if (DateService.isBefore(deadline, now)) {
    return 'Expired'
  }

  return DateService.duration(now, deadline).humanize()
}

export const formatExpiredDate = (reviewer) => {
  if (!reviewer.invited) {
    return ''
  }
  const { reminderRemoveReviewer, timeUnit } =
    DateService.getReviewerTimeConstants()
  const deadline = DateService.add(
    new Date(reviewer.invited),
    reminderRemoveReviewer,
    timeUnit,
  )
  return DateService.format(deadline)
}

export const getDaysCountFromAllExtensions = (reviewer) => {
  return reviewer?.submitReportExtensions?.reduce(
    (acc, extension) => acc + extension.extendedWith,
    0,
  )
}

const getDaysCountFromPresentExtensions = (
  reviewer,
  timeUnit: DurationBase,
  now: Date,
) => {
  return reviewer?.submitReportExtensions?.reduce((acc, extension) => {
    const singleExtensionDeadline = DateService.add(
      new Date(extension.extendedDate),
      extension.extendedWith,
      timeUnit,
    )

    return (
      acc +
      (DateService.isBefore(singleExtensionDeadline, now)
        ? 0
        : extension.extendedWith)
    )
  }, 0)
}

export const formatTimeToSubmitOrOverdue = (
  reviewer,
  reviewerReportTimeFrame,
): {
  formattedTime: string
  showStatusAs: string
} => {
  if (!reviewer.responded) {
    return {
      formattedTime: '',
      showStatusAs: 'n/a',
    }
  }

  const now = new Date()
  const { reminderOverdueAcceptedReviewer, timeUnit } =
    DateService.getReviewerTimeConstants(reviewerReportTimeFrame)

  const totalNumberOfDaysExtended = getDaysCountFromPresentExtensions(
    reviewer,
    timeUnit,
    now,
  )

  const deadline = DateService.add(
    new Date(reviewer.responded),
    reminderOverdueAcceptedReviewer + totalNumberOfDaysExtended,
    timeUnit,
  )

  if (reviewer.status === 'overdue' || DateService.isBefore(deadline, now)) {
    return {
      formattedTime: DateService.duration(new Date(), deadline).humanize(),
      showStatusAs: 'overdue',
    }
  }

  return {
    formattedTime: DateService.duration(now, deadline).humanize(),
    showStatusAs: 'accepted',
  }
}

export const formatTimeToSubmit = (reviewer, reviewerReportTimeFrame) => {
  if (!reviewer.responded) {
    return ''
  }

  const now = new Date()
  const { reminderOverdueAcceptedReviewer, timeUnit } =
    DateService.getReviewerTimeConstants(reviewerReportTimeFrame)

  const totalNumberOfDaysExtended = getDaysCountFromPresentExtensions(
    reviewer,
    timeUnit,
    now,
  )

  const deadline = DateService.add(
    new Date(reviewer.responded),
    reminderOverdueAcceptedReviewer + totalNumberOfDaysExtended,
    timeUnit,
  )

  if (DateService.isBefore(deadline, now)) {
    return 'Overdue'
  }

  return DateService.duration(now, deadline).humanize()
}

export const formatTimeOverdue = (reviewer, reviewerReportTimeFrame) => {
  if (!reviewer.responded) {
    return ''
  }

  const { reminderOverdueAcceptedReviewer, timeUnit } =
    DateService.getReviewerTimeConstants(reviewerReportTimeFrame)

  const totalNumberOfDaysExtended = getDaysCountFromPresentExtensions(
    reviewer,
    timeUnit,
    new Date(),
  )

  const deadline = DateService.add(
    new Date(reviewer.responded),
    reminderOverdueAcceptedReviewer + totalNumberOfDaysExtended,
    timeUnit,
  )

  return DateService.duration(new Date(), deadline)
    .humanize()
    .replace('ago', '')
}

/**
 * @deprecated use <Flex gap /> instead
 */
export const VSpace20 = styled(Space)`
  .ant-space-item {
    line-height: 20px;
    span.ant-typography,
    .ant-btn > span,
    .ant-btn {
      display: inline-block;
      line-height: 20px;
    }
  }
`
/**
 * @deprecated use <Flex gap /> instead
 */
export const VSpace16 = styled(Space)`
  .ant-space-item {
    line-height: 16px !important;

    .ant-typography {
      white-space: nowrap !important;
      word-break: keep-all !important;
    }
    span.ant-typography {
      display: inline-block;
      line-height: 16px !important;
    }
  }
`
