function generateSpaces(gridUnit = 4) {
  return Array.from({ length: 200 }, (_v, i) => i * gridUnit)
}

const validation = {
  errorColor: '#FC6A4B',
  warningColor: '#F89D10',
}

export default {
  validation,
  space: generateSpaces(),
}
