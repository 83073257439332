import { get } from 'lodash'

const jpgExtValue = 'image/jpeg'

const FILE_EXTENSIONS = {
  pdf: {
    label: 'PDF',
    value: 'application/pdf',
  },
  doc: {
    label: 'Doc',
    value: 'application/msword',
  },
  docx: {
    label: 'DocX',
    value:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  txt: {
    label: 'TXT',
    value: 'text/plain',
  },
  rtf: {
    label: 'RTF',
    value: 'text/rtf',
  },
  odt: {
    label: 'ODT',
    value: 'application/vnd.oasis.opendocument.text',
  },
  png: {
    label: 'PNG',
    value: 'image/png',
  },
  jpg: {
    label: 'JPG',
    value: jpgExtValue,
  },
  jpeg: {
    label: 'JPEG',
    value: jpgExtValue,
  },
}

const hasPreviewMimeTypes = [
  'application/pdf',
  'text/plain',
  'text/csv',
  jpgExtValue,
  'image/png',
  'image/svg+xml',
]

export const fileHasPreview = (file) =>
  hasPreviewMimeTypes.includes(get(file, 'mimeType'))

export const getAllowedMimeTypes = (allowedFileExtensions = []) =>
  allowedFileExtensions.map(
    (ext) => FILE_EXTENSIONS[ext.toLowerCase()]?.value || ext.toLowerCase(),
  )

export const getSupportedFileFormats = (allowedFileExtensions = []) =>
  allowedFileExtensions
    .map(
      (ext) => FILE_EXTENSIONS[ext.toLowerCase()]?.label || ext.toUpperCase(),
    )
    .join(', ')

export const parseFileSize = (file) => {
  const size = get(file, 'size')
  const kbSize = size / 1000
  const mbSize = kbSize / 1000
  const gbSize = mbSize / 1000

  if (Math.floor(gbSize)) {
    return `${Math.floor(gbSize)} GB`
  } else if (Math.floor(mbSize)) {
    return `${Math.floor(mbSize)} MB`
  } else if (Math.floor(kbSize)) {
    return `${Math.floor(kbSize)} kB`
  }
  return `${size} bytes`
}

export const createAnchorElement = (file, filename, isBase64 = false) => {
  const a = document.createElement('a')

  const anchorHref = isBase64 ? file : URL.createObjectURL(file)
  a.href = anchorHref
  a.download = filename
  document.body.appendChild(a)

  return {
    a,
    url: anchorHref,
  }
}

export const removeAnchorElement = (a, url) => {
  document.body.removeChild(a)
  setTimeout(() => URL.revokeObjectURL(url), 500)
}
