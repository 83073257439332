export type DurationBase =
  | 'year'
  | 'years'
  | 'y'
  | 'month'
  | 'months'
  | 'M'
  | 'day'
  | 'days'
  | 'd'
  | 'hour'
  | 'hours'
  | 'h'
  | 'minute'
  | 'minutes'
  | 'm'
  | 'second'
  | 'seconds'
  | 's'
  | 'millisecond'
  | 'milliseconds'
  | 'ms'
  | 'weeks'
  | 'week'
  | 'w'

export enum FormatType {
  LONG = 'long',
  SHORT = 'short',
  NORMAL = 'normal',
  LONG_NO_TIME = 'long_no_time',
  LONG_WITH_DAY = 'long_with_day',
}

export type InputDate = string | Date | number

export type DateObject = Partial<{
  years: number
  months: number
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}>

export type DurationResult = {
  years: number
  months: number
  days: number
  hours: number
  minutes: number
  seconds: number
  asDays: () => number
  humanize: () => string
  asMilliseconds: () => number
}
