import { Component, ReactNode } from 'react'

export class ErrorBoundary extends Component<{
  children: ReactNode
  fallback: ReactNode
}> {
  state = {
    hasError: false,
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }
    return this.props.children
  }
}
