import React, { ReactNode, Suspense, lazy } from 'react'
import { ROLES, ROUTES } from '@shared/ui/constants'
import { Routes, Navigate, Route } from 'react-router-dom'

import FourOFour from './FourOFour'
import { useHasAccessToDashboard } from '@shared/ui/hooks'
import { LoadingState } from './LoadingState'

// For better performance, we suspense the pages, so they are splitted into chunks
const ReviewerRedirect = lazy(() => import('./ReviewerRedirect'))
const Dashboard = lazy(
  () => import('../../component-dashboard/client/pages/Dashboard'),
)
const EADashboard = lazy(
  () => import('../../component-dashboard/client/pages/EADashboard'),
)
const AdminSearchDashboard = lazy(
  () => import('../../component-dashboard/client/pages/AdminDashboard'),
)

const InfoPage = lazy(
  () => import('../../component-authentication/client/pages/InfoPage'),
)
const ProtectedRoute = lazy(
  () => import('../../component-authentication/client/ProtectedRoute'),
)
const DownloadFileProtectedRoute = lazy(
  () =>
    import('../../component-authentication/client/DownloadFileProtectedRoute'),
)
const TrackedRoute = lazy(
  () => import('../../component-authentication/client/TrackedRoute'),
)
const ConfirmAccount = lazy(
  () => import('../../component-authentication/client/pages/ConfirmAccount'),
)
const ManuscriptDetails = lazy(
  () => import('../../component-peer-review/client/pages/ManuscriptDetails'),
)
const EmailResponse = lazy(
  () => import('../../component-peer-review/client/pages/EmailResponse'),
)
const ManuscriptDetailsGateway = lazy(
  () =>
    import(
      '../../component-peer-review/client/components/manuscriptDetails/ManuscriptDetailsGateway'
    ),
)
const DownloadFile = lazy(
  () => import('../../component-peer-review/client/pages/DownloadFile'),
)
const LoggedOut = lazy(
  () => import('../../component-user-profile/client/pages/LoggedOut'),
)

const Unsubscribe = lazy(
  () => import('../../component-user-profile/client/pages/Unsubscribe'),
)
const AdminUsers = lazy(
  () => import('../../component-admin/client/pages/AdminUsers'),
)
const JournalsPage = lazy(
  () => import('../../component-admin/client/pages/AdminJournals'),
)
const AdminJournalDetails = lazy(
  () => import('../../component-admin/client/pages/AdminJournalDetails'),
)
const AdminDashboard = lazy(
  () => import('../../component-admin/client/pages/AdminDashboard'),
)
const Invitation = lazy(
  () => import('../../component-admin/client/pages/Invitation'),
)
const UserProfilePage = lazy(
  () => import('../../component-user-profile/client/pages/UserProfilePage'),
)
const AdminEnMassJournalConfiguration = lazy(
  () =>
    import(
      '../../component-admin/client/pages/enmassJournalConfiguration/AdminEnMassJournalConfiguration'
    ),
)

function RouteManager(): ReactNode {
  const hasAccessTo = useHasAccessToDashboard()

  return (
    <Suspense fallback={<LoadingState />}>
      <Routes>
        <Route
          element={
            <ProtectedRoute
              allow={[hasAccessTo('editorialAssistantDashboard')]}
            >
              <EADashboard />
            </ProtectedRoute>
          }
          path={ROUTES.EA_DASHBOARD}
        />

        <Route
          element={<TrackedRoute component={<InfoPage />} />}
          path="/info-page"
        />

        <Route
          element={<TrackedRoute component={<EmailResponse />} />}
          path="/emails/:action"
        />

        <Route
          element={<TrackedRoute component={<ReviewerRedirect />} />}
          path="/invite-reviewer"
        />

        <Route
          element={
            <ProtectedRoute>
              <Invitation />
            </ProtectedRoute>
          }
          path="/invitation/:invitationId"
        />

        <Route
          element={
            <ProtectedRoute allow={[hasAccessTo('adminDashboard')]}>
              <AdminUsers />
            </ProtectedRoute>
          }
          path={ROUTES.ADMIN_USERS}
        />

        <Route
          element={
            <ProtectedRoute allow={[ROLES.ADMIN]}>
              <JournalsPage />
            </ProtectedRoute>
          }
          path={ROUTES.ADMIN_JOURNALS}
        />

        {process.env.ENABLE_ENMASS_JOURNAL_CONFIGURATION && (
          <Route
            element={
              <ProtectedRoute allow={[hasAccessTo('adminDashboard')]}>
                <AdminEnMassJournalConfiguration />
              </ProtectedRoute>
            }
            path={ROUTES.ENMASS_JOURNAL_CONFIGURATION}
          />
        )}

        <Route
          element={
            <ProtectedRoute allow={[ROLES.ADMIN]}>
              <AdminJournalDetails />
            </ProtectedRoute>
          }
          path={ROUTES.JOURNAL_DETAILS}
        />

        <Route
          element={
            <ProtectedRoute allow={[ROLES.ADMIN]}>
              <AdminDashboard />
            </ProtectedRoute>
          }
          path={ROUTES.ADMIN_DASHBOARD}
        />

        <Route
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
          path="/"
        />

        <Route
          element={
            <ProtectedRoute allow={[hasAccessTo('adminDashboard')]}>
              <AdminSearchDashboard />
            </ProtectedRoute>
          }
          path={ROUTES.ADMIN_SEARCH_DASHBOARD}
        />

        <Route
          element={
            <ProtectedRoute>
              <ManuscriptDetailsGateway />
            </ProtectedRoute>
          }
          path={ROUTES.MANUSCRIPT_DETAILS_GATEWAY}
        />

        <Route
          element={
            <ProtectedRoute>
              <ManuscriptDetails />
            </ProtectedRoute>
          }
          path={ROUTES.MANUSCRIPT_DETAILS}
        />

        <Route
          element={
            <ProtectedRoute>
              <UserProfilePage />
            </ProtectedRoute>
          }
          path={ROUTES.PROFILE}
        />

        <Route
          element={
            <ProtectedRoute>
              <ConfirmAccount />
            </ProtectedRoute>
          }
          path="/invite"
        />

        <Route
          element={<DownloadFileProtectedRoute component={DownloadFile} />}
          path="/reviewFiles/:fileId"
        />

        <Route
          element={<TrackedRoute component={<LoggedOut />} />}
          path={'/loggedOut'}
        />

        <Route
          element={<TrackedRoute component={<FourOFour />} />}
          path={ROUTES.PAGE_NOT_FOUND}
        />

        <Route
          element={<TrackedRoute component={<Unsubscribe />} />}
          path="/subscribe"
        />
        <Route
          element={<TrackedRoute component={<Unsubscribe />} />}
          path="/unsubscribe"
        />

        <Route
          path="*"
          element={<Navigate to={ROUTES.PAGE_NOT_FOUND} replace />}
        />
      </Routes>
    </Suspense>
  )
}

export default RouteManager
